import MenuItem from "@mui/material/MenuItem";
import React, {useState, useContext} from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import makeStyles from '@mui/styles/makeStyles';
import { DataContext } from "./DataContext";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    menu: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    button: {
        color: `${theme.palette.primary.contrastText} !important`
    }
}));

export default function PageMenu(props) {
    const [anchorEl, setAnchorEl] = useState(null);

    const newPage = props.currentPage === "QUESTS" ? "ACHIEVEMENTS" : "QUESTS";

    const classes = useStyles();
    const history = useHistory();
    const { genericData } = useContext(DataContext)

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                onClick={(e) => setAnchorEl(e.currentTarget)}
                className={classes.button}
            >
                <u>{props.currentPage}</u>
            </Button>
            <Menu
                className={classes.menu}
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                keepMounted
                onClose={handleClose}
             >
                <MenuItem onClick={() => history.push(`/${newPage.toLowerCase()}/${genericData.uuid}`)}>
                    {newPage}
                </MenuItem>
            </Menu>
        </div>
    );
}