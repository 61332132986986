import {colourParser} from "./FormatName";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';
import parse from 'html-react-parser';

const useStyles = makeStyles((theme) => ({
    formatted: {
        display: 'inline',
        textShadow: '1px 1px 1px rgba(0, 0, 0, 0.5)',
    }
}));

export default function Username(props) {
    const classes = useStyles();

    return (
        <Typography variant={props.variant} className={classes.formatted}>
            {parse(colourParser(props.name + "'" + (props.name[props.name.length-1].toLowerCase() !== 's' ? 's' : '')))}
        </Typography>
    )
}