import React, {createRef, useEffect} from "react";
import PieChart, {
    Series,
    Label,
    Connector,
    SmallValuesGrouping,
    Legend,
    Tooltip
} from 'devextreme-react/pie-chart';

export default function PerGamePie(props) {
    const pieChartRef = createRef();

    useEffect(() => {
        setTimeout(() => {
            if (pieChartRef.current != null) pieChartRef.current.instance.render()
        }, 500);
    }, [props.update])

    function customizeText(arg) {
        return `${arg.argumentText} - ${arg.valueText} (${arg.percentText})`
    }

    function customizeTooltip(arg) {
        return {text: `${arg.argument}: ${arg.valueText}`}
    }

    function pointClickHandler(event) {
        toggleVisibility(event.target);
    }

    function legendClickHandler(event) {
        let item = event.component.getAllSeries()[0].getPointsByArg(event.target)[0];
        toggleVisibility(item);
    }

    function toggleVisibility(item) {
        item.isVisible() ? item.hide() : item.show();
    }

    return(
        <PieChart
            ref={pieChartRef}
            dataSource={props.data.sort((obj1, obj2) => (
                obj2[props.value] - obj1[props.value]
            ))}
            resolveLabelOverlapping="hide"
            redrawOnResize={true}
            onPointClick={pointClickHandler}
            onLegendClick={legendClickHandler}
        >
            <Tooltip
                enabled={true}
                customizeTooltip={customizeTooltip}
            />
            <Series
                argumentField="game"
                valueField={props.value}
            >
                <Label visible={true} customizeText={customizeText} format="fixedPoint">
                    <Connector visible={true} width={0.5} />
                </Label>
                <SmallValuesGrouping threshold={1} mode="smallValueThreshold" />
            </Series>
            <Legend
                orientation={"horizontal"}
                itemTextPosition={"right"}
                verticalAlignment={"bottom"}
                horizontalAlignment={"center"}
            />
        </PieChart>
    )
}