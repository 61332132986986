import React, {useState} from "react";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';
import {ListItem} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import Chip from "@mui/material/Chip";
import useTheme from "@mui/material/styles/useTheme";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    objectiveDesc: {
        textAlign: 'left',
        color: theme.palette.text.secondary,
        fontSize: theme.typography.pxToRem(13),
        fontWeight: theme.typography.fontWeightNormal,
    },
    chip: {
        flexGrow: 1,
        textAlign: 'right',
        marginLeft: theme.spacing(0.5),
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.pxToRem(10),
    }
}));

export default function IndividualObjective(props) {
    const classes = useStyles();
    const theme = useTheme();
    const colour = getColour();

    function getColour() {
        const percentage = (props.objective.progress/props.objective.goal)*100
        if (percentage === 100) return theme.palette.success.main;
        else if (percentage < 25) return theme.palette.error.main;
        else if (percentage >= 75) return "#8bc34a";
        else return theme.palette.warning.main;
    }

    return (
        <ListItem>
            <ListItemText>
                <Typography className={classes.objectiveDesc}>
                    {props.objective.description}
                </Typography>
            </ListItemText>
            <span className={classes.chip}>
                <Chip size="small" style={{backgroundColor: colour, color: 'white'}} label={props.objective.progress + "/" + props.objective.goal} />
            </span>
        </ListItem>
    )
}