import React from "react";
import Username from "./Username";
import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles((theme) => ({
    inline: {
        display: 'inline',
        color: theme.palette.text.secondary,
        fontWeight: theme.typography.fontWeightLight,
    },
    paper: {
        padding: theme.spacing(2, 0, 2, 0),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    margin: {
        margin: theme.spacing(0, 0.75, 0.75, 0.75),
    }
}));

export default function NameTitle(props) {
    const classes = useStyles();
    return (
        <Grid item xs={12}>
            <Paper className={classes.paper}>
                <div className={props.margin ? classes.margin : ""}>
                    <Username variant={"h4"} name={props.colourName}/>
                    <Typography variant={"h4"} className={classes.inline}> {props.title}</Typography>
                </div>
            </Paper>
        </Grid>
    )
}