import React, {useEffect, useState} from "react";
import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import Leaderboard from "../../../common/Leaderboards/Leaderboard";
import LeaderboardSelector from "../../../common/Leaderboards/LeaderboardSelector";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    title: {
        [theme.breakpoints.up('xs')]: {
            textAlign: 'center'
        },
        [theme.breakpoints.up('sm')]: {
            textAlign: 'left'
        },
    }
}));

export default function RecordsLeaderboard(props) {
    const classes = useStyles();
    const selection = getSelection();
    const [selected, setSelected] = useState(selection[0]);
    const [headings, setHeadings] = useState(getHeadings);

    function getSelection() {
        return ["Monthly", "Yearly"]
    }

    function getHeadings() {
        if (selected === selection[0]) {
            return [{id: "quests", heading: "Quests"}, {id: "month", heading: "Month"}, {id: "year", heading: "Year"}]
        } else {
            return [{id: "quests", heading: "Quests"}, {id: "year", heading: "Year"}]
        }
    }

    useEffect(() => {
        setHeadings(getHeadings);
    },[selected]);

    return (
        <div>
            <Typography variant={"h4"} className={classes.title}>{"Records Leaderboard"}</Typography>
            <LeaderboardSelector
                choices={selection}
                getLabel={(label) => (label)}
                setSelected={(selected) => setSelected(selected)}
                getSelected={() => (selected)}
            />
            <Leaderboard ign={props.ign} uuid={props.uuid} page={"quests"} leaderboard={"records/"+selected.toLowerCase()} columns={headings}/>
        </div>
    )
}