import React, {useState} from "react";
import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import Leaderboard from "./Leaderboard";
import LeaderboardSelector from "./LeaderboardSelector";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    heading: {
        [theme.breakpoints.up('xs')]: {
            textAlign: 'center'
        },
        [theme.breakpoints.up('sm')]: {
            textAlign: 'left'
        },
    }
}));

export default function PerGameLeaderboards(props) {
    const classes = useStyles();
    const [game, setGame] = useState("arcade");

    function getGameDisplayName(game) {
        return props.data[game] ? props.data[game]["name"] :
            props.data["normal"][game] ? props.data["normal"][game]["name"] :
                props.data["special"][game]["name"]
    }

    function getGameObject(game) {
        return props.data[game] ? props.data[game] :
            props.data["normal"][game] ? props.data["normal"][game] :
                props.data["special"][game]
    }

    return (
        <div>
            <Typography variant={"h4"} className={classes.heading}>{getGameObject(game)["name"] + " Leaderboard"}</Typography>
            <LeaderboardSelector
                choices={
                    props.page === "achievements" ?
                        Object.keys(props.data["normal"]).sort((game1, game2) => (
                        props.data["normal"][game2]["name"].toUpperCase() > props.data["normal"][game1]["name"].toUpperCase() ? -1 : 1
                    )).filter((game) => props.data["normal"][game]["legacy"] === false).concat(
                        Object.keys(props.data["special"]).sort((game1, game2) => (
                            props.data["special"][game2]["name"].toUpperCase() > props.data["special"][game1]["name"].toUpperCase() ? -1 : 1
                        )).filter((game) => props.data["special"][game]["legacy"] === false)
                    ) :
                    Object.keys(props.data).sort((game1, game2) => (
                        props.data[game2]["name"].toUpperCase() > props.data[game1]["name"].toUpperCase() ? -1 : 1
                    ))
                }
                getLabel={getGameDisplayName}
                setSelected={(game) => setGame(game)}
                getSelected={() => (game)}
            />
            <Leaderboard ign={props.ign} getGameDisplayName={getGameDisplayName} max={getGameObject(game)["total_points"]} return={props.return} uuid={props.uuid} leaderboard={game} page={props.page} columns={props.columns}/>
        </div>
    )
}