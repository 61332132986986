import React, { useState, useRef } from "react";
import { withStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';
import { useStyles } from "./CompareUsers";
import { useSnackbar } from 'notistack';

export function CompareUser(props) {
    const classes = useStyles();
    const [ign, setIgn] = useState("");
    const textRef = useRef();
    const { enqueueSnackbar } = useSnackbar();

    function handleToggle(name) {
        props.handleToggle(name);
        props.toggleSeries(name, null);
    }

    function handleSearch() {
        if (ign === "") {
            enqueueSnackbar("You must enter a username to compare", {variant: "warning"});
            return;
        } else if (props.doesUserExist(ign)) {
            enqueueSnackbar(`'${ign}' is already being compared`, {variant: "warning"});
            return;
        }
        enqueueSnackbar(`Comparing '${ign}'...`, {variant: "info"});
        axios.get("/api/quests/graph_quests/" + ign)
            .then((response) => {
                if (response.data.success) {
                    props.handleToggle(response.data.data.name);
                    props.toggleSeries(response.data.data.name, response.data.data.quests, response.data.data.first_quest, response.data.data.last_quest);
                } else {
                    enqueueSnackbar(`Error: ${response.data.cause}`, {variant: "error"});
                }
                textRef.current.value = "";
                setIgn("");
            })
            .catch((error) => {
                enqueueSnackbar(`Error: ${error.message}`, {variant: "error"});
            });
    }

    const ColouredCheckbox = withStyles({
        root: {
            padding: 0,
            color: props.colour,
            '&$checked': {
                color: props.colour,
            },
        },
        checked: {},
    })((props) => <Checkbox color="default" {...props} />);

    return (
        <ListItem disableGutters key={"user_comparison_" + props.user.toString()}>
            <ListItemIcon className={props.displayed ? classes.noMarginIcon : classes.extraMarginIcon}>
                <ColouredCheckbox
                    onChange={props.displayed ? (e) => handleToggle(e.target.name) : () => handleSearch()}
                    checked={props.checkedUsers.indexOf(props.user) !== -1}
                    tabIndex={-1}
                    inputProps={{ 'aria-labelledby': props.user }}
                    name={props.user}
                />
            </ListItemIcon>
            {props.displayed &&
                <ListItemIcon>
                    <IconButton aria-label="delete" size="small" onClick={() => props.handleDelete(props.user)} >
                        <DeleteIcon
                            style={{ color: props.colour, opacity: props.displayed ? 1 : 0 }}
                        />
                    </IconButton>
                </ListItemIcon>}
                <form onSubmit={(e) => {
                    e.preventDefault();
                    handleSearch();
                }}>
                    <TextField
                        inputRef={textRef}
                        onChange={(e) => setIgn(e.target.value)}
                        defaultValue={props.displayed ? props.user : ""}
                        placeholder={"Compare player..."}
                        fullWidth
                        margin="none"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={props.displayed}
                    />
                </form>
        </ListItem>
    );
}
