import React, {useEffect, useState} from "react";
import Paper from "@mui/material/Paper";
import BuildIcon from "@mui/icons-material/Build";
import IconButton from "@mui/material/IconButton";
import makeStyles from '@mui/styles/makeStyles';
import {SettingsContext} from "./SettingsContext";
import Grid from "@mui/material/Grid";
import ButtonBase from "@mui/material/ButtonBase";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import { useTheme } from '@mui/material/styles';
import useWidth from './useWidth';

const useStyles = makeStyles((theme) => ({
    settingsMenu: {
        padding: theme.spacing(3)
    }
}));

export default function Settings(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const width = useWidth();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <IconButton
                aria-describedby={id}
                aria-label={"open settings menu"}
                aria-haspopup={"true"}
                onClick={handleClick}
            >
                <BuildIcon style={{color: "white"}}/>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Paper className={classes.settingsMenu}>
                    <div>
                        <SettingsContext.Consumer>
                            {({settings, toggleStickySidebar, toggleSidebarResizable, toggleAchievementProgressColours, toggleHighContrast}) => (
                                <div>
                                    <Typography variant={"h5"}>Settings</Typography>
                                    <Box>
                                        <FormControlLabel
                                            control={<Switch checked={settings["sidebar"]["sticky"]} color={"primary"} onChange={toggleStickySidebar} name="sticky" />}
                                            label="Sticky sidebar"
                                            disabled={width === 'sm' || width === 'xs'}
                                        />
                                    </Box>
                                    <Box>
                                        <FormControlLabel
                                            control={<Switch checked={settings["sidebar"]["resizable"]} color={"primary"} onChange={toggleSidebarResizable} name="resizeMenu" />}
                                            label="Enable sidebar resize"
                                        />
                                    </Box>
                                    <Box>
                                        <FormControlLabel
                                            control={<Switch checked={settings["achievements"]["old_progress_colour"]} color={"primary"} onChange={toggleAchievementProgressColours} name="achievementThemeColour" />}
                                            label="Old achievement progress colours"
                                        />
                                    </Box>
                                    <Box>
                                        <FormControlLabel
                                            control={<Switch checked={settings["theme"]["contrast"]} color={"primary"} onChange={toggleHighContrast} name="contrast" />}
                                            label="High contrast"
                                        />
                                    </Box>
                                </div>
                            )}
                        </SettingsContext.Consumer>
                        {"Theme colour"}
                        <Colours handleClose={handleClose}/>
                    </div>
                </Paper>
            </Popover>
        </div>
    )
}
function Colours(props) {
    const theme = useTheme();

    function onColourBoxClick(event, colour, setAccentColour) {
        props.handleClose(event);
        setAccentColour(colour)
    }

    function colourBox(setAccentColour, colour) {
        return (
            <Grid key={colour} item>
                <ButtonBase onClick={(event) => onColourBoxClick(event, colour, setAccentColour)} disabled={theme.palette.primary.main === colour}>
                    <Box bgcolor={colour} p={2} border={theme.palette.primary.main === colour ? 1 : 0}/>
                </ButtonBase>
            </Grid>
        )
    }

    return (
        <Grid container spacing={1}>
            <SettingsContext.Consumer>
                {({setAccentColour, getColours}) => (
                    getColours().map((colour) => (
                        colourBox(setAccentColour, colour)
                    ))
                )}
            </SettingsContext.Consumer>
        </Grid>
    )
}