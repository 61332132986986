import React from "react";
import FadeIn from 'react-fade-in';
import InfoCard from "../../../common/InfoCard";
import makeStyles from '@mui/styles/makeStyles';
import NameTitle from "../../../common/NameTitle";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import QuestsPerDaySpline from "./representations/QuestsPerDaySpline";
import {ColorLinearProgress} from "../../../common/ColorLinearProgress";
import {useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import PerGamePie from "../../../common/PerGamePie";
import PerGameTable from "../../../common/PerGameTable";
import Tooltip from '@mui/material/Tooltip';
import NetworkLevel from "../../../common/NetworkLevel";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    infoCards: {
        marginTop: theme.spacing(1.5),
        marginLeft: theme.spacing(0.75),
        marginRight: theme.spacing(0.75),
    },
    vertical: {
        display: 'grid',
    },
    chip: {
        marginBottom: theme.spacing(2)
    },
    width: {
        width: '100%'
    },
    italic: {
        fontWeight: theme.typography.fontWeightLight,
    },
    materialIcons: {
        display: 'inlineFlex',
        verticalAlign: 'bottom',
    },
    alignMid: {
        display: 'inlineBlock',
        verticalAlign: 'middle',
    }
}));

export default function QuestsStats(props) {
    const classes = useStyles();
    const theme = useTheme();

    function getQuests() {
        return (
            <div>
                {"Quests "}
                <Chip size={"small"} label={props.data.quests_completed.total}/>
            </div>
        )
    }

    function getNetworkLevel() {
        return (
            <NetworkLevel className={classes.width} data={props.data["network_level"]}/>
        )
    }

    function getAchievementPoints() {
        return (
            <div>
                {"Achievement Points "}
                <Chip size={"small"} label={props.data.achievements.points}/>
            </div>
        )
    }

    function getQuestsTime() {
        return (
            <div className={classes.vertical}>
                {"Quests today"}
                <Chip size={"small"} className={classes.chip} label={props.data.quests_completed["day"]}/>
                {"Quests this week"}
                <Chip size={"small"} className={classes.chip} label={props.data.quests_completed["week"]}/>
                {"Quests this month"}
                <Chip size={"small"} className={classes.chip} label={props.data.quests_completed["month"]}/>
                {"Quests this year"}
                <Chip size={"small"} className={classes.chip} label={props.data.quests_completed["year"]}/>
                {"Quests total"}
                <Chip size={"small"} className={classes.chip} label={props.data.quests_completed["total"]}/>
            </div>
        )
    }

    function getQuestsPerGamePie() {
        return (
            <PerGamePie value={"value1"} update={props.update} data={props.data.stats_data.quests_per_game}/>
        )
    }

    function getQuestsPerGameTable() {
        return (
            <PerGameTable valueHeader={"Quests"} valueData={"value1"} data={props.data.stats_data.quests_per_game}/>
        )
    }

    function getQuestsPerXGraph() {
        return (
            <QuestsPerDaySpline
                update={props.update}
                data={props.data.stats_data.quests_per_day}
                first={props.data.stats_data.first_quest}
                last={props.data.stats_data.last_quest}
                name={props.data.name}
            />
        )
    }

    function getTodayProgress() {
        return (
            <Tooltip title={Math.floor((props.data.today.dailies/props.data.today.possible_dailies)*100) + "% daily quests completed"}>
                <ColorLinearProgress
                    className={classes.width}
                    style={{
                        "--progress": theme.palette.primary.main,
                        "--progress-bg": theme.palette.primary.main + "33"
                    }}
                    variant="determinate"
                    value={(props.data.today.dailies / props.data.today.possible_dailies)*100}
                />
            </Tooltip>
        )
    }

    function getTodayStats() {
        return (
            <div>
                <p className={classes.alignMid}>
                    {`Weekly quests today `}
                    <Chip size={"small"} label={props.data.today.weekliesDoneToday}/>
                </p>
                <p className={classes.alignMid}>
                    <PlusOneIcon className={classes.materialIcons}/>
                    {` Exp earned today `}
                    <Chip size={"small"} label={props.data.today.exp.toLocaleString()}/>
                </p>
                <p className={classes.alignMid}>
                    <AttachMoneyIcon className={classes.materialIcons}/>
                    {`Coins earned today `}
                    <Chip size={"small"} label={props.data.today.coins.toLocaleString()}/>
                </p>
            </div>
        )
    }

    function getTodayDailies() {
        return (
            <div className={classes.width}>
                <Typography variant="h6" className={classes.italic} align={"left"}>
                    <i>{`Daily quests today`}</i>
                </Typography>
                <Typography variant="h3" align={"right"}>
                    <b><i>{`${props.data.today.dailies} / ${props.data.today.possible_dailies}`}</i></b>
                </Typography>
            </div>
        )
    }

    return (
        <FadeIn>
            <div className={classes.root}>
                <div className={classes.infoCards}>
                    <Grid container spacing={2}>
                        <NameTitle colourName={props.data["colour_name"]} title={"Questing Stats"} margin={false}/>
                        <InfoCard center xsCols={6} mdCols={4} padding text={getQuests()}/>
                        <InfoCard center xsCols={6} mdCols={4} padding text={getNetworkLevel()}/>
                        <InfoCard center xsCols={12} mdCols={4} padding text={getAchievementPoints()}/>
                        <InfoCard center xsCols={12} mdCols={6} padding text={getTodayProgress()}/>
                        <InfoCard center xsCols={12} mdCols={3} padding text={getTodayDailies()}/>
                        <InfoCard center xsCols={12} mdCols={3} text={getTodayStats()}/>
                        <InfoCard xsCols={12} mdCols={5} lgCols={3} padding text={getQuestsPerGameTable()}/>
                        <InfoCard xsCols={12} mdCols={7} lgCols={7} text={getQuestsPerGamePie()}/>
                        <InfoCard center padding xsCols={12} mdCols={12} lgCols={2} text={getQuestsTime()}/>
                        <InfoCard updateChart={props.update} padding xsCols={12} text={getQuestsPerXGraph()}/>
                    </Grid>
                </div>
            </div>
        </FadeIn>
    )
}