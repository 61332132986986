import React from 'react';
import Tabs from "@mui/material/Tabs";
import makeStyles from '@mui/styles/makeStyles';
import Tab from "@mui/material/Tab";
import {SettingsContext} from "../common/SettingsContext";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        paddingBottom: theme.spacing(5),
    },
    inline: {
        display: 'inline',
    },
}));

export default function PageSelector(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <SettingsContext.Consumer>
                {({settings, setPage}) => (
                    <Tabs
                        value={settings["page"]["value"]}
                        onChange={(e, v) => setPage(v)}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab disabled={props.disabled} value={"QUESTS"} label="Quests" />
                        <Tab disabled={props.disabled} value={"ACHIEVEMENTS"} label="Achievements" />
                    </Tabs>
                )}
            </SettingsContext.Consumer>
        </div>
    )
}