import React, {useState} from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import makeStyles from '@mui/styles/makeStyles';
import TablePagination from "@mui/material/TablePagination";
import TableFooter from "@mui/material/TableFooter";
import * as PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import useTheme from "@mui/material/styles/useTheme";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import TableBody from "@mui/material/TableBody";

const useStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    bold: {
        fontWeight: theme.typography.fontWeightBold
    },
    alignMid: {
        display: 'inline-flex',
        verticalAlign: 'middle',
        cursor: 'pointer'
    },
    materialIcons: {
        display: 'inline-flex',
        verticalAlign: 'middle',
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

export default function PerGameTable(props) {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(8);

    const [sortBy, setSortBy] = useState(1);
    const [orderBy, setOrderBy] = useState(1);

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.data.length - page * rowsPerPage);

    function handleSort(sort) {
        sortBy !== sort ? setOrderBy(1) : setOrderBy(1-orderBy);
        setSortBy(sort)
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    return(
        <TableContainer>
            <Table size="small" className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>Game</TableCell>
                        <TableCell onClick={() => handleSort(1)}>
                            <span className={classes.alignMid}>
                                {sortBy === 1 ? orderBy === 1 ? <ArrowDownwardIcon fontSize={"small"} className={classes.materialIcons}/> : <ArrowUpwardIcon fontSize={"small"} className={classes.materialIcons}/> : ""}
                                {props.valueHeader}
                            </span>
                        </TableCell>
                        {props.data[0].hasOwnProperty("value2") ?
                            <TableCell onClick={() => handleSort(2)}>
                                <span className={classes.alignMid}>
                                    {sortBy === 2 ? orderBy === 1 ? <ArrowDownwardIcon fontSize={"small"} className={classes.materialIcons}/> : <ArrowUpwardIcon fontSize={"small"} className={classes.materialIcons}/> : ""}
                                    {"Points"}
                                </span>
                            </TableCell> : ""}
                        {props.data[0].hasOwnProperty("value3") ?
                            <TableCell onClick={() => handleSort(3)}>
                                <span className={classes.alignMid}>
                                    {sortBy === 3 ? orderBy === 1 ? <ArrowDownwardIcon fontSize={"small"} className={classes.materialIcons}/> : <ArrowUpwardIcon fontSize={"small"} className={classes.materialIcons}/> : ""}
                                    {"Achievements"}
                                </span>
                            </TableCell> : ""}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0
                        ? props.data.sort((obj1, obj2) => (
                            orderBy === 1 ?
                                (obj1["value"+sortBy] < obj2["value"+sortBy] ? 1 : -1) :
                                (obj1["value"+sortBy] > obj2["value"+sortBy] ? 1 : -1)
                        )).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : props.data.sort((obj1, obj2) => (
                            orderBy === 1 ?
                                (obj1["value"+sortBy] < obj2["value"+sortBy] ? 1 : -1) :
                                (obj1["value"+sortBy] > obj2["value"+sortBy] ? 1 : -1)
                        ))
                    ).map((game) => (
                        <TableRow key={game.game} hover>
                            <TableCell component="th" scope="row">
                                {game.game}
                            </TableCell>
                            <TableCell className={game.hasOwnProperty("value2") && game["value1"]===100 ? classes.bold : ""}>{game[props.valueData]}{game.hasOwnProperty("value2") ? "%" : ""}</TableCell>
                            {game.hasOwnProperty("value2") ?
                                <TableCell>{game["value2"]}</TableCell> : ""}
                            {game.hasOwnProperty("value3") ?
                                <TableCell>{game["value3"]}</TableCell> : ""}
                        </TableRow>
                    ))}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 32.5 * emptyRows }}>
                            <TableCell colSpan={6} />
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[]}
                            count={props.data.length}
                            rowsPerPage={rowsPerPage}
                            labelDisplayedRows={() => ("")}
                            page={page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                            }}
                            onPageChange={(e, page) => setPage(page)}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    )
}