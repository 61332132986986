import React, {useContext, useEffect, useState} from 'react';
import {IndividualThemeButton} from "../common/ThemeButtons";
import {Container} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import SearchForm from "../common/SearchForm";
import PageSelector from "./PageSelector";
import makeStyles from '@mui/styles/makeStyles';
import bottle from '../assets/bottle.gif'
import Typography from "@mui/material/Typography";
import {SettingsContext} from "../common/SettingsContext";
import {DataContext} from "../common/DataContext";
import { useSnackbar } from 'notistack';
import './Home.css'

const useStyles = makeStyles((theme) => ({
    group: {
        marginTop: theme.spacing(8),
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        marginBottom: theme.spacing(2)
    },
    title: {
        fontWeight: theme.typography.fontWeightLight
    },
    logo: {
        marginBottom: theme.spacing(2)
    }
}));

export default function Home(props) {
    const [isLoading, setIsLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const {settings} = useContext(SettingsContext);
    const { error, setError } = useContext(DataContext);

    const classes = useStyles();

    useEffect(() => {
        if (error === "") enqueueSnackbar("Enter a username or UUID", {variant: "info"});
        else enqueueSnackbar(error, {variant: "error"})
        setError("");
    }, []);

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.group}>
                <div className={classes.center}>
                    <img className={classes.logo} src={bottle} alt={"Logo"}/>
                    <Typography variant={"h2"} className={classes.title}>Notifly.zone</Typography>
                </div>
                <PageSelector disabled={isLoading}/>
                <SearchForm
                    page={settings["page"]["value"]}
                    handleLoading={(loading) => setIsLoading(loading)}
                    snackLocation={{vertical: 'bottom', horizontal: 'right'}}
                />
            </div>
            <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'right'}} open={true}>
                <IndividualThemeButton/>
            </Snackbar>
        </Container>
    )
}
