import React, {useEffect, useState} from "react";
import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import Leaderboard from "../../../common/Leaderboards/Leaderboard";
import LeaderboardSelector from "../../../common/Leaderboards/LeaderboardSelector";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    title: {
        [theme.breakpoints.up('xs')]: {
            textAlign: 'center'
        },
        [theme.breakpoints.up('sm')]: {
            textAlign: 'left'
        },
    }
}));

export default function YearlyLeaderboard(props) {
    const classes = useStyles();
    const years = getYears();
    const [year, setYear] = useState(years[0]);

    function getYears() {
        let year = new Date().getFullYear();
        let years = [];
        let earliestYear = 2016;
        while (year >= earliestYear) {
            years.push(year);
            year--;
        }
        return years;
    }

    useEffect(() => {
    },[]);

    return (
        <div>
            <Typography variant={"h4"} className={classes.title}>{"Yearly Leaderboards"}</Typography>
            <LeaderboardSelector
                choices={years}
                getLabel={(year) => (year)}
                setSelected={(year) => setYear(year)}
                getSelected={() => (year)}
            />
            <Leaderboard ign={props.ign} uuid={props.uuid} page={"quests"} leaderboard={year} columns={[{id: "quests", heading: "Total Quests"}]}/>
        </div>
    )
}