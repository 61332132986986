import React from "react";
import Chip from "@mui/material/Chip";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    games: {
        margin: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}));

export default function LeaderboardSelector(props) {
    const classes = useStyles();

    function handleClick(choice) {
        props.setSelected(choice)
    }

    return (
        <div className={classes.games}>
            {props.choices.map((choice) => (
                <Chip
                    color={choice === props.getSelected() ? "primary" : "default"}
                    key={choice}
                    label={props.getLabel(choice)}
                    onClick={() => handleClick(choice)}
                />
            ))}
        </div>
    )
}