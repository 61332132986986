import { withStyles } from '@mui/styles';
import LinearProgress from "@mui/material/LinearProgress";

export const ColorLinearProgress = withStyles((theme) => ({
    root: {
        height: '8px !important',
        borderColor: "black",
        borderRadius: 3,
        backgroundColor: "var(--progress-bg)",
    },
    bar: {
        backgroundColor: "var(--progress)",
        backgroundImage: "var(--backgroundImage)",
        backgroundSize: "var(--backgroundSize)",
    },
}))(LinearProgress);