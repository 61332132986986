import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, {Suspense, useState, useEffect} from 'react';
import './home/Home.css';
import 'typeface-roboto';
import {Route, BrowserRouter as Router, Switch} from "react-router-dom";
import Home from "./home/Home";
import Quests from "./quests/Quests";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Achievements from "./achievements/Achievements";
import Mod from "./mod/Mod";
import useMediaQuery from "@mui/material/useMediaQuery";
import {SettingsContext} from "./common/SettingsContext";
import update from 'immutability-helper';
import { SnackbarProvider } from 'notistack';
import { DataContext } from "./common/DataContext";
import CssBaseline from "@mui/material/CssBaseline";

export default function App() {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [settings, setSettings] = useState(getInitialSettings);
    const [theme, setTheme] = useState(generateTheme(settings["theme"]["dark"], settings["theme"]["accent"], settings["theme"]["contrast"]));
    
    const [questData, setQuestData] = useState({});
    const [achievementData, setAchievementData] = useState({});
    const [genericData, setGenericData] = useState({});
    const [error, setError] = useState("");

    function handleSetQuestData(newData) {
        setQuestData(newData);
        setAchievementData({});
        setGenericData({
            name: newData.name,
            names: newData.names,
            uuid: newData.uuid
        });
    }

    function handleSetAchievementData(newData) {
        setAchievementData(newData);
        setQuestData({});
        setGenericData({
            name: newData.name,
            names: newData.names,
            uuid: newData.uuid
        });
    }

    useEffect(() => {
        saveSettings();
    }, [settings]);

    function generateTheme(type, accent, contrast) {
        if (contrast) {
            return createTheme({
                palette: {
                    mode: type ? "dark" : "light",
                    primary:  {
                        main: accent
                    },
                    success: {
                        main: "#9ccc65",
                        contrastText: "#000000"
                    },
                    error: {
                        main: "#b71c1c",
                        contrastText: "#ffffff"
                    },
                    warning: {
                        main: "#ff6f00",
                        contrastText: "#000000"
                    },
                },
            })
        } else {
            return createTheme({
                palette: {
                    mode: type ? "dark" : "light",
                    primary:  {
                        main: accent
                    },
                },
            })
        }
    }

    function saveSettings() {
        localStorage.setItem("settings", JSON.stringify(settings));
    }

    function toggleTheme() {
        setSettings(update(settings, {
            theme: {dark: {$apply: (current) => (!current)}}
        }));
        setTheme(generateTheme(!settings["theme"]["dark"], settings["theme"]["accent"], settings["theme"]["contrast"]))
    }

    function toggleHighContrast() {
        setSettings(update(settings, {
            theme: {
                contrast: {
                    $apply: (current) => (!current)
                }
            }
        }));
        setTheme(generateTheme(settings["theme"]["dark"], settings["theme"]["accent"], !settings["theme"]["contrast"]))
    }

    function setAccentColour(accent) {
        setSettings(update(settings, {
            theme: {
                accent: {
                    $set: accent
                }
            }
        }));
        setTheme(generateTheme(settings["theme"]["dark"], accent, settings["theme"]["contrast"]))
    }

    function setPage(page) {
        setSettings(update(settings, {
            page: {value: {$set: page}}
        }))
    }

    function handleTypeChange(type) {
        setSettings(update(settings, {
            achievements: {'type': {$set: type}}
        }));
    }

    function handleStatusChange(status) {
        setSettings(update(settings, {
            achievements: {'status': {$set: status}}
        }));
    }

    function handleStatusAndTypeChange(type, status) {
        setSettings(update(settings, {
            achievements: {
                'type': {$set: type},
                'status': {$set: status}
            }
        }));
    }

    function handleSizeChange(size) {
        setSettings(update(settings, {
            achievements: {size: {$set: size}}
        }));
    }

    function handleMetricChange(metric) {
        setSettings(update(settings, {
            achievements: {metric: {$set: metric}}
        }));
    }

    function toggleStickySidebar() {
        setSettings(update(settings, {
            sidebar: {sticky: {$apply: (current) => (!current)}}
        }))
    }

    function toggleSidebarResizable() {
        setSettings(update(settings, {
            sidebar: {resizable: {$apply: (current) => (!current)}}
        }))
    }

    function toggleAchievementProgressColours() {
        setSettings(update(settings, {
            achievements: {old_progress_colour: {$apply: (old_progress_colour) => (!old_progress_colour)}}
        }))
    }

    function toggleQuestsProgress() {
        setSettings(update(settings, {
            quests: {progress: {$apply: (progress) => (!progress)}}
        }))
    }

    function toggleCompletedQuests() {
        setSettings(update(settings, {
            quests: {completed: {$apply: (completed) => (!completed)}}
        }))
    }

    function toggleBookmark(game, category, type, achievement) {
        // copy of object
        let newSettings = Object.assign({}, settings);

        // add needed objects
        if (!newSettings.bookmarks.hasOwnProperty(category)) newSettings.bookmarks[category] = {}; 
        if (!newSettings.bookmarks[category].hasOwnProperty(game)) newSettings.bookmarks[category][game] = {}; 
        if (!newSettings.bookmarks[category][game].hasOwnProperty(type)) newSettings.bookmarks[category][game][type] = {};

        // if achievement not seen, add achievement, else delete
        if (!newSettings.bookmarks[category][game][type].hasOwnProperty(achievement)) {
            newSettings.bookmarks[category][game][type][achievement] = {};
        } else {
            delete newSettings.bookmarks[category][game][type][achievement];
        }

        // remove empty objects
        if (Object.keys(newSettings.bookmarks[category][game][type]).length === 0) delete newSettings.bookmarks[category][game][type];
        if (Object.keys(newSettings.bookmarks[category][game]).length === 0) delete newSettings.bookmarks[category][game];
        if (Object.keys(newSettings.bookmarks[category]).length === 0) delete newSettings.bookmarks[category];

        setSettings(newSettings);
    }

    function isBookmarked(game, category, type, achievement) {
        return settings.bookmarks[category][game][type].hasOwnProperty(achievement)
    }

    function setInitialSetting(defaultValue, parent, key) {
        const save = localStorage.getItem("settings");
        return save === null
            ? defaultValue
            : parent === null
                ? JSON.parse(save).hasOwnProperty(key)
                    ? JSON.parse(save)[key]
                    : defaultValue
                : JSON.parse(save).hasOwnProperty(parent) && JSON.parse(save)[parent].hasOwnProperty(key)
                    ? JSON.parse(save)[parent][key]
                    : defaultValue;       
    }
    
    function getInitialSettings() {
        return {
            theme: {
                accent: setInitialSetting("#b388ff", "theme", "accent"),
                dark: setInitialSetting(prefersDarkMode, "theme", "dark"),
                contrast: setInitialSetting(false, "theme", "contrast")
            },
            sidebar: {
                width: setInitialSetting(300, "sidebar", "width"),
                sticky: setInitialSetting(true, "sidebar", "sticky"),
                resizable: setInitialSetting(false, "sidebar", "resizable")
            },
            page: {
                value: setInitialSetting("QUESTS", "page", "value")
            },
            achievements: {
                type: setInitialSetting("Both", "achievements", "type"),
                status: setInitialSetting("Both", "achievements", "status"),
                size: setInitialSetting("Narrow", "achievements", "size"),
                metric: setInitialSetting("Points", "achievements", "metric"),
                old_progress_colour: setInitialSetting(false, "achievements", "old_progress_colour")
            },
            quests: {
                progress: setInitialSetting(false, "quests", "progress"),
                completed: setInitialSetting(true, "quests", "completed")
            },
            bookmarks: setInitialSetting({}, null, "bookmarks")
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <SettingsContext.Provider value={{
                settings,
                setPage,
                setAccentColour,
                getColours,
                toggleTheme,
                toggleStickySidebar,
                toggleSidebarResizable,
                handleTypeChange,
                handleStatusChange,
                handleSizeChange,
                handleMetricChange,
                toggleAchievementProgressColours,
                toggleQuestsProgress,
                toggleHighContrast,
                toggleBookmark,
                isBookmarked,
                handleStatusAndTypeChange,
                toggleCompletedQuests
            }}>
                <DataContext.Provider value={{
                    questData,
                    setQuestData: handleSetQuestData,
                    achievementData,
                    setAchievementData: handleSetAchievementData,
                    genericData,
                    error,
                    setError
                }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <CssBaseline />
                        <Router>
                            <SnackbarProvider
                                maxSnack={3}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }}
                            >
                                <Suspense fallback={<div>Loading...</div>}>
                                    <Switch>
                                        <Route path="/quests/:ign" component={Quests}/>
                                        <Route path="/quests/" component={Home}/>
                                        <Route path="/achievements/:ign" component={Achievements}/>
                                        <Route path="/achievements/" component={Home}/>
                                        <Route path="/mod" component={Mod}/>
                                        <Route path="/" component={Home}/>
                                        <Route component={Home}/>
                                    </Switch>
                                </Suspense>
                            </SnackbarProvider>
                        </Router>
                    </LocalizationProvider>
                </DataContext.Provider>
            </SettingsContext.Provider>
        </ThemeProvider>
    );
}

export function getColours() {
    return ["#b388ff", "#3f51b5", "#bf0a48", "#f44336", "#ff9800", "#2196f3", "#4caf50"]
}