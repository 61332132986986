import makeStyles from '@mui/styles/makeStyles';
import AppBar from "@mui/material/AppBar";
import { useTheme } from '@mui/material/styles';
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import React from "react";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import SearchForm from "./SearchForm";
import PageMenu from "./PageMenu";
import Settings from "../common/Settings"

export default function TopMenu(props) {

    const theme = useTheme();

    const useStyles = makeStyles((theme) => ({
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
        },
        hide: {
            display: 'none',
        },
        page: {
            fontWeight: theme.typography.fontWeightLight,
        },
        colourMenu: {
            width: 30,
            height: 30,
        },
        iconButton: {
            marginLeft: theme.spacing(0.5)
        },
        topLeft: {
            display: 'flex',
            alignItems: 'center',
            flexGrow: 1,
        },
        left: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: theme.spacing(1)
        },
        slash: {
            fontWeight: theme.typography.fontWeightLight
        }
    }));

    const classes = useStyles();

    return (
        <AppBar position="fixed" sx={appbarStyle(theme, props.drawerWidth, props.open)}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => props.setOpen(true)}
                    edge="start"
                    sx={{
                        display: props.open ? 'none' : 'inherit',
                        mr: 2,
                        ml: -1.5,
                        p: 1.5
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <div className={classes.topLeft}>
                    <div className={classes.left}>
                        <Typography variant="h6" noWrap className={classes.title}>
                            <i>Notifly.zone</i>
                            <span className={classes.slash}> / </span>
                        </Typography>
                        <PageMenu
                            currentPage={props.page}
                            className={classes.page}
                        />
                        <Typography Typography variant="h6"> <span className={classes.slash}>/</span> </Typography>
                    </div>
                    <SearchForm
                        page={props.page}
                        snackLocation={{vertical: 'bottom', horizontal: 'right'}}
                        maxWidth={'300px'}
                    />
                </div>
                <Settings/>
            </Toolbar>
        </AppBar>
    )
}

const appbarStyle = (theme, drawerWidth, open) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
})