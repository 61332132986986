import React from 'react';
import {Button, Container} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import screenshot1 from "../assets/screenshot1.png";
import screenshot2 from "../assets/screenshot2.png";
import screenshot3 from "../assets/screenshot3.png";
import screenshot4 from "../assets/screenshot4.png";


const useStyles = makeStyles((theme) => ({
    group: {
        marginTop: theme.spacing(8),
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        margin: theme.spacing(2, 0, 2, 0)
    },
    textCenter: {
        textAlign: 'center',
    },
    title: {
        margin: theme.spacing(3, 0, 3, 0)
    },
    commands: {
        margin: theme.spacing(2, 0, 2, 0),
        textAlign: "center"
    },
    logo: {
        marginBottom: theme.spacing(2)
    },
    highlight: {
        ...theme.typography.button,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
    },
    download: {
        marginTop: theme.spacing(2)
    }
}));

const commands = [
    {cmd: "/q daily", desc: "Shows daily quests for game that you are in"},
    {cmd: "/q weekly", desc: "Shows weekly quests for game that you are in"},
    {cmd: "/q daily [game] {ign}", desc: "Shows daily quests for game & optional ign specified"},
    {cmd: "/q weekly [game] {ign}", desc: "Shows weekly quests for game & optional ign specified"},
    {cmd: "/q games", desc: "Lists gamemode aliases"},
    {cmd: "/q info {ign}", desc: "View general hypixel stats"},
    {cmd: "/q site {ign}", desc: "Link to player's notifly.zone page"}
]

export default function Mod() {
    const classes = useStyles();

    return (
        <Container component={"main"} maxWidth={"sm"}>
            <CssBaseline />
            <div className={classes.center}>
                <Link href="/" className={classes.textCenter}>
                    <Typography variant={"body1"}>return to</Typography>
                    <Typography variant={"h4"}>Notifly.zone</Typography>
                </Link>
            </div>
            <div className={classes.group}>
                <Typography variant={"h3"} align={"center"} className={classes.title}>The Questing Mod</Typography>
                <Typography align={"center"} className={classes.summary}>
                    A 1.8.9 forge mod to make quest tracking easier. Type <span className={classes.highlight}>/q help</span> in game for list of commands.
                </Typography>
                <div className={classes.commands}>
                    <Grid container>
                        <Grid item xs={4}>
                            <Typography variant={"body2"}><b>Commands</b></Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant={"body2"}><b>Description</b></Typography>
                        </Grid>
                    </Grid>
                    {commands.map((command) => (
                        <Grid container>
                            <Grid item xs={4}>
                                <Typography variant={"body2"}><b>{command.cmd}</b></Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant={"body2"}>{command.desc}</Typography>
                            </Grid>
                        </Grid>
                    ))}
                </div>
                <ImageList rowHeight={160} className={classes.imageList} cols={3}>
                    <ImageListItem cols={1}>
                        <img src={screenshot1} alt={"Blitz daily quests"} />
                    </ImageListItem>
                    <ImageListItem cols={1}>
                        <img src={screenshot2} alt={"Build Battle daily quests command"} />
                    </ImageListItem>
                    <ImageListItem cols={1}>
                        <img src={screenshot4} alt={"Help command"} />
                    </ImageListItem>
                    <ImageListItem cols={3}>
                        <img src={screenshot3} alt={"Stats command"} />
                    </ImageListItem>
                </ImageList>
                <Grid className={classes.download}>
                    <Grid item align="center">
                        <Button variant={"contained"} href={"/mod/download"}>Download v3.0</Button>
                    </Grid>
                </Grid>
            </div>
        </Container>
    )
}
