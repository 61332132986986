import React, {useContext, useEffect, useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PersonIcon from '@mui/icons-material/Person';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import Filter1Icon from '@mui/icons-material/Filter1';
import Filter7Icon from '@mui/icons-material/Filter7';
import BarChartIcon from '@mui/icons-material/BarChart';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import GetAppIcon from '@mui/icons-material/GetApp';
import Collapse from "@mui/material/Collapse";
import {Link, useRouteMatch} from "react-router-dom";
import SideDrawer from "../common/SideDrawer";
import {SettingsContext} from "../common/SettingsContext";
import useWidth from "../common/useWidth";
import { CssBaseline } from '@mui/material';

export default function QuestsDrawer(props) {
    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            marginLeft: props.drawerWidth,
            width: `calc(100% - ${props.drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: 36,
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: props.drawerWidth + "px",
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        drawerOpen: {
            width: props.drawerWidth + "px",
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.spacing(7) + 1,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9) + 1,
            },
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            flexGrow: 1,
            [theme.breakpoints.up('xs')]: {
                padding: theme.spacing(0.5),
            },
            [theme.breakpoints.up('sm')]: {
                padding: theme.spacing(3),
            },
        },
        grow: {
            flexGrow: 1,
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputRoot: {
            color: 'inherit',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: '20ch',
            },
        },
        nested: {
            margin: 0,
            paddingLeft: theme.spacing(6),
        },
        rightIcon: {
            paddingLeft: theme.spacing(2)
        },
        link: {
            color: "inherit",
            textDecoration: "inherit"
        },
        noselect: {
            userSelect: 'none'
        },
        dragger: {
            width: '5px',
            height: '100%',
            cursor: 'ew-resize',
            padding: '4px 0 0',
            borderTop: '1px solid #ddd',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            zIndex: '100',
            backgroundColor: '#f4f7f9'
        },
        row: {
            display: 'flex'
        },
        icon: {
            flexGrow: 1,
            marginRight: theme.spacing(1)
        },
        nodecor: {
            textDecoration: 'none',
            color: 'inherit'
        }
    }));
    const classes = useStyles();
    let { url } = useRouteMatch();

    const { settings } = useContext(SettingsContext);
    const width = useWidth();
    console.log(width)
    const [open, setOpen] = useState(width === 'sm' || width === 'xs' ? false : settings["sidebar"]["sticky"]);
    const [lbListOpen, setlbListOpen] = useState(false);

    useEffect(() => {
        props.updateChart();
        if (!open) setlbListOpen(false);
    }, [open])

    /**
     * When user clicks on leaderboard expand button:
     * - Open draw if not already
     * - Toggles lb drop down
     */
    function handleToggleLBList() {
        if (!open) setOpen(true);
        setlbListOpen(!lbListOpen)
    }

    function drawerItemClick() {
        if (!settings["sidebar"]["sticky"] || width === 'sm' || width === 'xs') {
            setOpen(false)
        }
    }

    return (
        <div className={classes.root}>
            <SideDrawer
                handleSuccess={props.handleSuccess}
                handleError={props.handleSuccess}
                updateChart={props.updateChart}
                open={open}
                setOpen={(open) => setOpen(open)}
                data={props.data}
                page={"QUESTS"}
            >
                <List>
                    <Link className={classes.link} to={url}>
                        <ListItem button onClick={drawerItemClick}>
                            <ListItemIcon><PersonIcon /></ListItemIcon>
                            <ListItemText primary={"Stats"} />
                        </ListItem>
                    </Link>
                </List>
                <Divider />
                <List>
                    <Link className={classes.link} to={`${url}${url.slice(-1) === "/" ? "" : "/"}daily`}>
                        <ListItem button onClick={drawerItemClick}>
                            <ListItemIcon><Filter1Icon /></ListItemIcon>
                            <ListItemText primary={"Daily Quests"} />
                        </ListItem>
                    </Link>
                    <Link className={classes.link} to={`${url}${url.slice(-1) === "/" ? "" : "/"}weekly`}>
                        <ListItem button onClick={drawerItemClick}>
                            <ListItemIcon><Filter7Icon /></ListItemIcon>
                            <ListItemText primary={"Weekly Quests"} />
                        </ListItem>
                    </Link>
                    <ListItem button onClick={handleToggleLBList}>
                        <ListItemIcon><BarChartIcon/></ListItemIcon>
                        <ListItemText primary={"Leaderboards"} />
                        <ListItemIcon className={classes.rightIcon}>
                            {lbListOpen ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}
                        </ListItemIcon>
                    </ListItem>
                    <Collapse component="li" in={lbListOpen} timeout="auto" unmountOnExit>
                        <List disablePadding>
                            <Link className={classes.link} to={`${url}${url.slice(-1) === "/" ? "" : "/"}leaderboard`}>
                                <ListItem button onClick={drawerItemClick}>
                                    <ListItemText className={classes.nested} primary={"Overall"}/>
                                </ListItem>
                            </Link>
                            <Link className={classes.link} to={`${url}${url.slice(-1) === "/" ? "" : "/"}leaderboard/games`}>
                                <ListItem button onClick={drawerItemClick}>
                                    <ListItemText className={classes.nested} primary={"Games"}/>
                                </ListItem>
                            </Link>
                            <Link className={classes.link} to={`${url}${url.slice(-1) === "/" ? "" : "/"}leaderboard/monthly`}>
                                <ListItem button onClick={drawerItemClick}>
                                    <ListItemText className={classes.nested} primary={"Monthly"}/>
                                </ListItem>
                            </Link>
                            <Link className={classes.link} to={`${url}${url.slice(-1) === "/" ? "" : "/"}leaderboard/yearly`}>
                                <ListItem button onClick={drawerItemClick}>
                                    <ListItemText className={classes.nested} primary={"Yearly"}/>
                                </ListItem>
                            </Link>
                            <Link className={classes.link} to={`${url}${url.slice(-1) === "/" ? "" : "/"}leaderboard/records`}>
                                <ListItem button onClick={drawerItemClick}>
                                    <ListItemText className={classes.nested} primary={"Records"}/>
                                </ListItem>
                            </Link>
                        </List>
                    </Collapse>
                </List>
                <Divider />
                <List>
                    <Link className={classes.link} to={`${url}${url.slice(-1) === "/" ? "" : "/"}calendar`}>
                        <ListItem button onClick={drawerItemClick}>
                            <ListItemIcon><CalendarTodayIcon/></ListItemIcon>
                            <ListItemText primary={"Calendar"} />
                        </ListItem>
                    </Link>
                </List>
                <Divider />
                <List>
                    <a className={classes.nodecor} href={`/mod`} target={"_blank"}>
                        <ListItem button>
                            <ListItemIcon><GetAppIcon/></ListItemIcon>
                            <ListItemText primary={"Questing Mod"} />
                        </ListItem>
                    </a>
                </List>
            </SideDrawer>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {props.children}
            </main>
        </div>
    );
}
