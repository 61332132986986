import React, {useContext, useEffect, useState} from 'react';
import { useTheme } from '@mui/material/styles';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PersonIcon from '@mui/icons-material/Person';
import BarChartIcon from '@mui/icons-material/BarChart';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Collapse from "@mui/material/Collapse";
import {Link, useRouteMatch} from "react-router-dom";
import {ColorLinearProgress} from "../common/ColorLinearProgress";
import Typography from "@mui/material/Typography";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import SideDrawer from "../common/SideDrawer";
import {SettingsContext} from "../common/SettingsContext";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import useWidth from '../common/useWidth';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        width: `calc(100% - 57px)`,
        [theme.breakpoints.up('xs')]: {
            padding: theme.spacing(0.5),
        },
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(3),
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    grow: {
        flexGrow: 1,
    },
    nested: {
        margin: 0,
        paddingLeft: theme.spacing(6),
    },
    rightIcon: {
        paddingLeft: theme.spacing(2)
    },
    link: {
        color: "inherit",
        textDecoration: "inherit",
        width: '100%'
    },
    width: {
        width: '100%',
    },
    game: {
        display: 'list-item',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 0,
    },
    metric: {
        display: 'list-item',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: theme.spacing(1),
    },
    gameProgress: {
        display: 'flex',
        width: '100%',
    },
    gameName: {
        fontWeight: theme.typography.fontWeightBold,
        textAlign: 'left',
    },
    progress: {
        textAlign: 'right',
        flexGrow: 1,
        fontWeight: theme.typography.fontWeightLight
    },
    row: {
        display: 'flex'
    },
    icon: {
        flexGrow: 1,
        marginRight: theme.spacing(1)
    },
    pad: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1.5)
    }
}));

export default function AchievementsDrawer(props) {

    const classes = useStyles();
    const theme = useTheme();

    const { settings, handleMetricChange } = useContext(SettingsContext);
    const width = useWidth();

    const [open, setOpen] = useState(width === 'sm' || width === 'xs' ? false : settings["sidebar"]["sticky"]);
    const [lbListOpen, setlbListOpen] = useState(false);
    const [gamesListOpen, setGamesListOpen] = useState(width === 'sm' || width === 'xs' ? false : settings["sidebar"]["sticky"]);

    const metric = Object.freeze({points:"Points", achievements:"Achievements"})

    const { url } = useRouteMatch();

    /**
     * Closes drop down when drawer is closed
     */
    useEffect(() => {
        if (!open) setlbListOpen(false);
        if (!open) setGamesListOpen(false);
    }, [url]);

    useEffect(() => {
        props.updateChart()
        if (!open) {
            setGamesListOpen(false);
            setlbListOpen(false);
        }
    }, [open]);

    /**
     * When user clicks on leaderboard expand button:
     * - Open draw if not already
     * - Toggles lb drop down
     */
    function handleToggleLBList() {
        if (!open) setOpen(true);
        setlbListOpen(!lbListOpen)
    }

    /**
     * When user clicks on games expand button:
     * - Open draw if not already
     * - Toggles games drop down
     */
    function handleToggleGamesList() {
        if (!open) setOpen(true);
        setGamesListOpen(!gamesListOpen)
    }

    function drawerItemClick() {
        if (!settings["sidebar"]["sticky"] || width === 'sm' || width === 'xs') {
            setOpen(false);
        }
    }

    return (
        <div className={classes.root}>
            <SideDrawer
                updateChart={props.updateChart}
                open={open}
                setOpen={(open) => setOpen(open)}
                data={props.data}
                page={"ACHIEVEMENTS"}
            >
                <List>
                    <Link className={classes.link} to={url}>
                        <ListItem button onClick={drawerItemClick}>
                            <ListItemIcon><PersonIcon /></ListItemIcon>
                            <ListItemText primary={"Stats"} />
                        </ListItem>
                    </Link>
                </List>
                <Divider/>
                <List>
                    <Link className={classes.link} to={`${url}${url.slice(-1) === "/" ? "" : "/"}bookmarks`}>
                        <ListItem button onClick={drawerItemClick}>
                            <ListItemIcon><BookmarksIcon /></ListItemIcon>
                            <ListItemText primary={"Bookmarks"} />
                        </ListItem>
                    </Link>
                </List>
                <Divider/>
                <List>
                    <Link className={classes.link} to={`${url}${url.slice(-1) === "/" ? "" : "/"}legacy`}>
                        <ListItem button onClick={drawerItemClick}>
                            <ListItemIcon><AccessTimeIcon /></ListItemIcon>
                            <ListItemText primary={"Legacy"} />
                        </ListItem>
                    </Link>
                </List>
                <Divider/>
                <List>
                    <ListItem button onClick={handleToggleLBList}>
                        <ListItemIcon><BarChartIcon/></ListItemIcon>
                        <ListItemText primary={"Leaderboards"} />
                        <ListItemIcon className={classes.rightIcon}>
                            {lbListOpen ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}
                        </ListItemIcon>
                    </ListItem>
                    <Collapse component="li" in={lbListOpen} timeout="auto" unmountOnExit>
                        <List disablePadding>
                            <Link className={classes.link} to={`${url}${url.slice(-1) === "/" ? "" : "/"}leaderboard`}>
                                <ListItem button onClick={drawerItemClick}>
                                    <ListItemText className={classes.nested} primary={"Overall"}/>
                                </ListItem>
                            </Link>
                            <Link className={classes.link} to={`${url}${url.slice(-1) === "/" ? "" : "/"}leaderboard/games`}>
                                <ListItem button onClick={drawerItemClick}>
                                    <ListItemText className={classes.nested} primary={"Games"}/>
                                </ListItem>
                            </Link>
                            <Link className={classes.link} to={`${url}${url.slice(-1) === "/" ? "" : "/"}leaderboard/maxed`}>
                                <ListItem button onClick={drawerItemClick}>
                                    <ListItemText className={classes.nested} primary={"Maxed"}/>
                                </ListItem>
                            </Link>
                        </List>
                    </Collapse>
                </List>
                <Divider />
                <List>
                <ListItem button onClick={handleToggleGamesList}>
                    <ListItemIcon><SportsEsportsIcon/></ListItemIcon>
                    <ListItemText primary={"Games"} />
                    <ListItemIcon className={classes.rightIcon}>
                        {gamesListOpen ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}
                    </ListItemIcon>
                </ListItem>
                <Collapse component="li" in={gamesListOpen} timeout="auto" unmountOnExit>
                    <List disablePadding>
                        <ListItem className={classes.metric}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Metric</FormLabel>
                                    <RadioGroup row aria-label="metric" name="metric" value={settings["achievements"]["metric"]} onChange={(event) => handleMetricChange(event.target.value)}>
                                        <FormControlLabel value={metric.points} control={<Radio color={"primary"} />} label={metric.points} />
                                        <FormControlLabel value={metric.achievements} control={<Radio color={"primary"} />} label={metric.achievements} />
                                    </RadioGroup>
                            </FormControl>
                        </ListItem>
                        <DrawerAchievements drawerItemClick={drawerItemClick} width={props.width} theme={theme} settings={settings} achievements={props.data["achievements"]["normal"]} games={props.data["games"]} setOpen={(open) => setOpen(open)}/>
                        <Divider className={classes.pad}/>
                        <DrawerAchievements drawerItemClick={drawerItemClick} width={props.width} theme={theme} settings={settings} achievements={props.data["achievements"]["special"]} games={props.data["games"]} setOpen={(open) => setOpen(open)}/>
                    </List>
                </Collapse>
            </List>
            </SideDrawer>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {props.children}
            </main>
        </div>
    );
}

function DrawerAchievements(props) {
    const classes = useStyles();
    let { url } = useRouteMatch();

    function getPercentageColour(percentage) {

        let colour = "rgb(";

        const colour1 = "rgb(140,190,167)"; // 1st
        const colour2 = "rgb(28,110,140)"; // 2nd
        const colour3 = "rgb(170,80,170)"; // 3rd

        let range = 0;

        const reg = /rgb\((\d{1,3}),(\d{1,3}),(\d{1,3})\)/m;

        let sel1;
        let sel2;

        if (percentage < 0.5) {
            percentage = (percentage*2);
            sel1 = colour1;
            sel2 = colour2;
        } else {
            percentage = (percentage-0.5)*2;
            sel1 = colour2;
            sel2 = colour3;
        }

        let val1 = 0;
        let val2 = 0;

        for (let i=1;i<4;i++) {
            val1 = parseInt(sel1.match(reg)[i]);
            val2 = parseInt(sel2.match(reg)[i]);
            range = Math.abs(val1-val2);
            if (i===1) {
                if (val1 > val2) {
                    colour += (val1 - Math.floor(range*percentage));
                } else {
                    colour += (val1 + Math.floor(range*percentage));
                }
            } else {
                if (val1 > val2) {
                    colour += "," + (val1 - Math.floor(range*percentage));
                } else {
                    colour += "," + (val1 + Math.floor(range*percentage));
                }
            }
        }
        colour += ")";

        return colour;
    }

    function findTint(percentage) {
        let tint = "#";
        for (let n=1; n<=3; n++)
            tint += padHex((Math.round(parseInt(props.theme.palette.primary.main.substr(n*2-1,2),16)*percentage).toString(16)));
        return tint
    }

    function getColour(percentage) {
        return props.settings["achievements"]["old_progress_colour"] ? getPercentageColour(percentage) : findTint(percentage)
    }

    function padHex(hex) {
        if (hex.length === 1) return "0"+hex;
        return hex;
    }

    return (
        Object.keys(props.achievements).filter((game) => props.achievements[game]["legacy"] === false).sort((game1, game2) => (
            props.achievements[game2]["name"] > props.achievements[game1]["name"] ? -1 : 1
        )).map((game) =>
            <Link key={game} onClick={props.drawerItemClick} className={classes.link} to={`${url}${url.slice(-1) === "/" ? game : "/" + game}`}>
                    <SettingsContext.Consumer>
                        {({settings}) => (
                            <ListItem button className={classes.game}>
                                <span className={classes.gameProgress}>
                                    <Typography variant={"button"} className={classes.gameName}>{props.games[game]["name"]}</Typography>
                                    <Typography variant={"button"} className={classes.progress}>
                                        {" " + Math.floor((props.games[game]["earned_"+settings["achievements"]["metric"].toLowerCase()]/props.games[game]["total_"+settings["achievements"]["metric"].toLowerCase()])*100)}
                                        {"% (" + props.games[game]["earned_"+settings["achievements"]["metric"].toLowerCase()]}/{props.games[game]["total_"+settings["achievements"]["metric"].toLowerCase()] + ")"}
                                    </Typography>
                                </span>
                                <ColorLinearProgress
                                    className={classes.width}
                                    style={(props.games[game]["earned_"+settings["achievements"]["metric"].toLowerCase()] / props.games[game]["total_"+settings["achievements"]["metric"].toLowerCase()])===1 ? {
                                        "--progress": getColour(props.games[game]["earned_"+settings["achievements"]["metric"].toLowerCase()] / props.games[game]["total_"+settings["achievements"]["metric"].toLowerCase()]),
                                        "--progress-bg": props.settings["achievements"]["old_progress_colour"] ? props.theme.palette.background.default + "33" : props.theme.palette.primary.main + "33",
                                        "--backgroundImage": "linear-gradient(45deg,rgba(255,255,255,.35) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.35) 50%,rgba(255,255,255,.35) 75%,transparent 75%,transparent)",
                                        "--backgroundSize": "1rem 1rem"
                                    }:{
                                        "--progress": getColour(props.games[game]["earned_"+settings["achievements"]["metric"].toLowerCase()] / props.games[game]["total_"+settings["achievements"]["metric"].toLowerCase()]),
                                        "--progress-bg": settings["achievements"]["old_progress_colour"] ? props.theme.palette.background.default + "33" : props.theme.palette.primary.main + "33",
                                    }}
                                    variant="determinate"
                                    value={(props.games[game]["earned_"+settings["achievements"]["metric"].toLowerCase()] / props.games[game]["total_"+settings["achievements"]["metric"].toLowerCase()]) * 100}
                                />
                            </ListItem>
                        )}
                    </SettingsContext.Consumer>
                </Link>
        )
    )
}