import React from "react";
import FadeIn from 'react-fade-in';
import QuestsGrid from "../QuestsGrid";
import NameTitle from "../../../../common/NameTitle";

export default function Weekly(props) {
    return (
        <FadeIn>
            <NameTitle colourName={props.data["colour_name"]} title={"Weekly Quests"} margin={false}/>
            <QuestsGrid today={props.data["today"]} type={"weeklies"} quests={props.data["quests"]["weekly"]} orderedGames={props.data["sorted_game_ids"]}/>
        </FadeIn>
    )
}