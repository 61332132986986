import React, {useEffect, useState} from "react";
import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import Leaderboard from "../../../common/Leaderboards/Leaderboard";
import DatePicker from '@mui/lab/DatePicker';
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    datePicker: {
        width: "100%",
    },
    title: {
        [theme.breakpoints.up('xs')]: {
            textAlign: 'center'
        },
        [theme.breakpoints.up('sm')]: {
            textAlign: 'left'
        },
    }
}));

export default function MonthlyLeaderboard(props) {
    const classes = useStyles();
    const [month, setMonth] = useState(new moment());

    return (
        <div>
            <Typography variant={"h4"} className={classes.title}>{"Monthly Leaderboards"}</Typography>
            <Leaderboard
                ign={props.ign}
                page={"quests"}
                uuid={props.uuid}
                leaderboard={month.format("YYYY/MM")}
                columns={[{id: "quests", heading: "Total Quests"}]}
            >
                <DatePicker
                    className={classes.datePicker}
                    openTo="month"
                    views={["year", "month"]}
                    helperText="Select Year and Month"
                    minDate={new Date("2016-01")}
                    maxDate={new Date()}
                    value={month}
                    onChange={(date) => setMonth(date)}
                />
            </Leaderboard>
        </div>
    )
}