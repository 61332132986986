import FadeIn from 'react-fade-in';
import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import Grid from "@mui/material/Grid";
import {useTheme} from "@mui/material";
import {ColorLinearProgress} from "../../common/ColorLinearProgress";
import NameTitle from "../../common/NameTitle";
import InfoCard from "../../common/InfoCard";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { withStyles } from '@mui/styles';
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import Chip from "@mui/material/Chip";
import HoverChip from "../HoverChip";
import PerGameTable from "../../common/PerGameTable";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    infoCards: {
        marginTop: theme.spacing(1.5),
        marginLeft: theme.spacing(0.75),
        marginRight: theme.spacing(0.75),
    },
    vertical: {
        display: 'grid',
    },
    chip: {
        marginBottom: theme.spacing(2)
    },
    width: {
        width: '100%'
    },
    italic: {
        fontWeight: theme.typography.fontWeightLight,
    },
    materialIcons: {
        display: 'inlineFlex',
        verticalAlign: 'bottom',
    },
    alignMid: {
        display: 'inlineBlock',
        verticalAlign: 'middle',
    },
    table: {
        margin: theme.spacing(2, 0, 1, 0)
    }
}));

export default function LegacyAchievements(props) {
    const classes = useStyles();
    const theme = useTheme();

    const StyledTableHeader = withStyles((theme) => ({
        root: {
            borderBottomStyle: 'solid',
            borderColor: theme.palette.text.disabled,
            borderWidth: 2
        },
    }))(TableRow);

    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }))(TableRow);

    function getCompletionProgressBar(title1, achievement1, title2, achievement2) {
        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    {getCompletionProgressText(achievement1, title1, true)}
                    <Tooltip
                        title={Math.floor((props.data["stats"]["achievements"]["earned_"+achievement1]/props.data["stats"]["achievements"]["total_"+achievement1])*100) + "% " + title1 + " earned"}
                        arrow
                        placement={"top"}
                    >
                        <ColorLinearProgress
                            className={classes.width}
                            style={{
                                "--progress": theme.palette.primary.main,
                                "--progress-bg": theme.palette.primary.main + "33"
                            }}
                            variant="determinate"
                            value={(props.data["stats"]["achievements"]["earned_"+achievement1] / props.data["stats"]["achievements"]["total_"+achievement1])*100}
                        />
                    </Tooltip>
                </Grid>
                <Grid item xs={12}>
                    <Tooltip
                        title={Math.floor((props.data["stats"]["achievements"]["earned_"+achievement2]/props.data["stats"]["achievements"]["total_"+achievement2])*100) + "% " + title2 + " completed"}
                        arrow
                        placement={"bottom"}
                    >
                        <ColorLinearProgress
                            className={classes.width}
                            style={{
                                "--progress": theme.palette.primary.main,
                                "--progress-bg": theme.palette.primary.main + "33"
                            }}
                            variant="determinate"
                            value={(props.data["stats"]["achievements"]["earned_"+achievement2] / props.data["stats"]["achievements"]["total_"+achievement2])*100}
                        />
                    </Tooltip>
                    {getCompletionProgressText(achievement2, title2, false)}
                </Grid>
            </Grid>
        )
    }

    function getCompletionProgressText(stat, title, topLeft) {
        return (
            <div className={classes.width}>
                {topLeft ? getProgressTitle(title, "left") : getProgressValues(props.data["stats"]["achievements"]["earned_" + stat].toLocaleString(),
                    props.data["stats"]["achievements"]["total_" + stat].toLocaleString(), "right")}
                {topLeft ? getProgressValues(props.data["stats"]["achievements"]["earned_" + stat].toLocaleString(),
                    props.data["stats"]["achievements"]["total_" + stat].toLocaleString(), "left") : getProgressTitle(title, "right")}
            </div>
        )
    }

    function getProgressTitle(title, align) {
        return (
            <Typography variant="h6" className={classes.italic} align={align}>
                <i>{title}</i>
            </Typography>
        )
    }

    function getProgressValues(value1, value2, align) {
        return (
            <Typography variant="h3" align={align}>
                <b><i>{`${value1} / ${value2}`}</i></b>
            </Typography>
        )
    }

    function getLegacyAchievementsTable() {
        return (
            <div>
                <PerGameTable
                    valueHeader={"Progress"}
                    valueData={"value1"}
                    data={props.data["stats"]["legacy_games"]}
                />
            </div>
        )
    }

    function getLegacyAchievementsList() {
        return (
            <div>
                {["normal", "special"].map((group) => (
                    Object.keys(props.data["achievements"][group]).filter((game) => props.data["achievements"][group][game]["has_completed_legacy"]).map((game) => (
                        getGameTable(game, props.data["achievements"][group][game])
                    ))
                ))}
            </div>
        )
    }

    function getGameTiered(achievements) {
        return (
            Object.keys(achievements).filter((achievement => achievements[achievement]["legacy"] && achievements[achievement]["tiers_completed"] > 0)).map((achievement) => (
                <StyledTableRow key={achievement}>
                    <TableCell>
                        {achievements[achievement]["name"]}
                    </TableCell>
                    <TableCell>
                        {achievements[achievement]["description"].split('%s')[0]}
                        <Chip size="small" label={
                            Math.min(achievements[achievement]["next_goal"], achievements[achievement]["progress"])
                            + "/" +
                            achievements[achievement]["next_goal"]}/>
                        {achievements[achievement]["description"].split('%s')[1]}
                    </TableCell>
                    <TableCell className={classes.points}>
                        {achievements[achievement]["tiers"].map((tier) => {
                            return (
                                <HoverChip
                                    tier={tier}
                                />
                            )
                        })}
                    </TableCell>
                </StyledTableRow>
            ))
        )
    }

    function getGameOneTime(achievements) {
        return (
            Object.keys(achievements).filter((achievement => achievements[achievement]["legacy"] && achievements[achievement]["completed"])).map((achievement) => (
                <StyledTableRow key={achievement}>
                    <TableCell>
                        {achievements[achievement]["name"]}
                    </TableCell>
                    <TableCell>
                        {achievements[achievement]["description"]}
                    </TableCell>
                    <TableCell>
                        <Chip size="small" style={{color: "white", backgroundColor: `${achievements[achievement]["completed"] ? theme.palette.success.main : theme.palette.error.main}`}} label={achievements[achievement]["points"]} />
                    </TableCell>
                </StyledTableRow>
            ))
        )
    }

    function getGameTable(game, gameObject) {
        return (
            <div className={classes.table}>
                <Typography variant={"h3"}>{gameObject["name"]}</Typography>
                <TableContainer>
                    <Table className={classes.table} size={"small"}>
                        <TableHead>
                            <StyledTableHeader>
                                <TableCell align="left">
                                    {"Name"}
                                </TableCell>
                                <TableCell align="left">
                                    {"Description"}
                                </TableCell>
                                <TableCell align="left">
                                    {"Points"}
                                </TableCell>
                            </StyledTableHeader>
                        </TableHead>
                        <TableBody>
                            {getGameTiered(gameObject["tiered"])}
                            {getGameOneTime(gameObject["one_time"])}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        )
    }

    return (
        <FadeIn>
            <div className={classes.root}>
                <div className={classes.infoCards}>
                    <Grid container spacing={2}>
                        <NameTitle colourName={props.data["colour_name"]} title={"Legacy Achievements"} margin={false}/>
                        <InfoCard center smCols={12} mdCols={6} padding text={getCompletionProgressBar("Legacy points", "legacy_points", "Legacy achievements", "legacy_achievements")}/>
                        <InfoCard center smCols={12} mdCols={6} padding text={getLegacyAchievementsTable()}/>
                        <InfoCard center xsCols={12} padding text={getLegacyAchievementsList()}/>
                    </Grid>
                </div>
            </div>
        </FadeIn>
    )
}
