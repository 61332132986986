import { createContext } from "react";

export const DataContext = createContext({
    questData: {},
    setQuestData: (newData) => null,
    achievementData: {},
    setAchievementData: (newData) => null,
    genericData: {},
    error: {},
    setError: (errorMessage) => null
});