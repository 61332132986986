import React, { useState, forwardRef, useCallback } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar, SnackbarContent } from 'notistack';
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            minWidth: '344px !important',
        },
    },
    icon: {
        marginInlineEnd: theme.spacing(0.5),
    },
    actionRoot: {
        padding: theme.spacing(0.75, 2, 0.75, 2.5),
        justifyContent: 'space-between',
    },
    icons: {
        marginLeft: 'auto',
    },
    expand: {
        padding: '8px 8px',
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    collapse: {
        padding: 16,
    },
    checkIcon: {
        fontSize: 20,
        color: '#b3b3b3',
        paddingRight: 4,
    },
    button: {
        padding: 0,
        textTransform: 'none',
    },
    warning: {
        width: '100%',
        backgroundColor: theme.palette.warning.main
    },
    info: {
        width: '100%',
        backgroundColor: theme.palette.info.main
    },
    error: {
        width: '100%',
        backgroundColor: theme.palette.error.main
    },
    success: {
        width: '100%',
        backgroundColor: theme.palette.success.main
    },
    link: {
        textDecoration: "none",
        color: theme.palette.text.primary
    },
    iconMessage: {
        display: "flex",
        alignItems: "center"
    }
}));

export const BookmarkSnackbar = forwardRef((props, ref) => {
    const classes = useStyles();
    const { closeSnackbar } = useSnackbar();
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = useCallback(() => {
        setExpanded((oldExpanded) => !oldExpanded);
    }, []);

    const handleDismiss = useCallback(() => {
        closeSnackbar(props.id);
    }, [props.id, closeSnackbar]);

    function getIcon() {
        switch (props.icon) {
            case "success":
                return <CheckIcon className={classes.icon} fontSize={"small"} />
            case "error":
                return <ErrorIcon className={classes.icon} fontSize={"small"} />
            case "warning":
                return <WarningIcon className={classes.icon} fontSize={"small"} />
            case "add":
                return <AddCircleIcon className={classes.icon} fontSize={"small"} />
            case "remove":
                return <RemoveCircleIcon className={classes.icon} fontSize={"small"} />
            default:
                return <InfoIcon className={classes.icon} fontSize={"small"} />
        }
    }

    function cleanURL(url) {
        if (url.split('/').pop() === "")  return url.split('/').slice(0, -2).join('/');
        else return url.split('/').slice(0, -1).join('/');
    }

    return (
        <SnackbarContent ref={ref} className={classes.root}>
            <Card className={classes[props.type]}>
                <CardActions classes={{ root: classes.actionRoot }}>
                    <div className={classes.iconMessage}>
                        {getIcon()}
                        {props.message}
                    </div>
                    <div className={classes.icons}>
                        {props.extra && 
                            <IconButton
                                // className={classnames(classes.expand, { [classes.expandOpen]: !expanded })}
                                className={{...classes.expand, ...(expanded ? classes.expandOpen : {})}}
                                onClick={handleExpandClick}
                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        }
                        <IconButton className={classes.expand} onClick={handleDismiss}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </CardActions>
                {props.extra && 
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <Paper className={classes.collapse}>
                            <Typography variant={"body1"}>
                                
                            </Typography>
                            <Button size="small" className={classes.button}>
                                <BookmarkIcon className={classes.checkIcon} />
                                <Link
                                    className={classes.link}
                                    to={`${cleanURL(props.url)}/bookmarks`}
                                >
                                    View all Bookmarks
                                </Link>
                            </Button>
                        </Paper>
                    </Collapse>
                }
            </Card>
        </SnackbarContent>
    );
});