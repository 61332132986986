import React, {useEffect, useState} from "react";
import makeStyles from '@mui/styles/makeStyles';
import List from '@mui/material/List';
import { useSnackbar } from 'notistack';
import { CompareUser } from "./CompareUser";

export const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    formControl: {
        minWidth: 60,
        textAlign: 'left',
    },
    buttons: {
        display: 'grid'
    },
    item: {
        alignSelf: 'center'
    },
    noMarginIcon: {
        minWidth: 20,
    },
    extraMarginIcon: {
        minWidth: 81,
    }
}));

export default function CompareUsers(props) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [users, setUsers] = useState([props.name]);
    const [checkedUsers, setCheckedUsers] = useState([props.name]);


    useEffect(() => {
        setUsers([props.name]);
        setCheckedUsers([props.name]);
    }, [props.name])

    function handleToggle(name) {
        const currentCheckedIndex = checkedUsers.indexOf(name);
        const currentUsersIndex = users.indexOf(name);

        const newChecked = [...checkedUsers];
        const newUsers = [...users];
    
        if (currentCheckedIndex === -1) newChecked.push(name);
        else newChecked.splice(currentCheckedIndex, 1);

        enqueueSnackbar(currentCheckedIndex > -1 ? `Hiding ${name} from comparison` : `Showing ${name} in comparison`, {variant: "info"});

        if (currentUsersIndex === -1) newUsers.push(name)
        
        setUsers(newUsers);
        setCheckedUsers(newChecked);
    }

    function handleDelete(name) {
        const currentCheckedIndex = checkedUsers.indexOf(name);
        const currentUsersIndex = users.indexOf(name);

        const newChecked = [...checkedUsers];
        const newUsers = [...users];
    
        newChecked.splice(currentCheckedIndex, 1);
        newUsers.splice(currentUsersIndex, 1);

        enqueueSnackbar("Removed '" + name + "' from comparison", {variant: "info"});
        
        setUsers(newUsers);
        setCheckedUsers(newChecked);

        props.handleDelete(name);
    }

    function doesUserExist(name) {
        for (let i in users)
            if (name.toLowerCase() === users[i].toLowerCase())
                return true;
        
        return false;
    }

    return (
        <div>
            <List className={classes.list} dense component="div" role="list">
                {users.map((user) =>
                    <CompareUser
                        key={user}
                        colour={props.colours[users.indexOf(user)]}
                        user={user}
                        displayed={true}
                        handleToggle={(name) => handleToggle(name)}
                        toggleSeries={(name) => props.toggleSeries(name)}
                        checkedUsers={checkedUsers}
                        doesUserExist={(e) => doesUserExist(e)}
                        handleDelete={(name) => handleDelete(name)}
                    />
                )}
                {(users.length < 7) &&
                    <CompareUser
                        user={""}
                        displayed={false}
                        handleToggle={(name) => handleToggle(name)}
                        toggleSeries={(name, newData, first_quest, last_quest) => props.toggleSeries(name, newData, first_quest, last_quest)}
                        checkedUsers={checkedUsers}
                        doesUserExist={(e) => doesUserExist(e)}
                    />
                }
            </List>
        </div>
    )
}