import React, {useEffect, useState, useContext} from 'react';
import '../home/Home.css';
import axios from "axios";
import {useParams, Route, Switch, useRouteMatch, useHistory} from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import QuestsDrawer from "./QuestsDrawer";
import QuestsStats from "./tabs/Stats/QuestsStats";
import Daily from "./tabs/Quests/DailyQuests/Daily";
import Weekly from "./tabs/Quests/WeeklyQuests/Weekly";
import Calendar from "./tabs/Calendar/Calendar";
import OverallLeaderboard from "../common/Leaderboards/OverallLeaderboard";
import Typography from "@mui/material/Typography";
import MonthlyLeaderboard from "./tabs/Leaderboards/MonthlyLeaderboard";
import YearlyLeaderboard from "./tabs/Leaderboards/YearlyLeaderboard";
import RecordsLeaderboard from "./tabs/Leaderboards/RecordsLeaderboard";
import PerGameLeaderboards from "../common/Leaderboards/PerGameLeaderboards";
import {faDove} from "@fortawesome/free-solid-svg-icons/faDove";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import { DataContext } from '../common/DataContext';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: 10,
        padding: 0
    },
    loading: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '15%'
    },
    load: {
        marginBottom: theme.spacing(2)
    }
}));

export default function Quests() {
    const [chartUpdate, setChartUpdate] = useState(false);
    let { path } = useRouteMatch();

    const { ign } = useParams();
    const classes = useStyles();
    
    const history = useHistory();

    const { questData, setQuestData, setError, genericData } = useContext(DataContext);

    useEffect(() => {
        if (Object.keys(questData).length === 0
            || !genericData?.hasOwnProperty("name")
            || !genericData?.hasOwnProperty("uuid")
            || (ign !== genericData.uuid
                && ign.toLowerCase() !== genericData.name.toLowerCase())) {
            setQuestData({});
            fetchData();
        }
    }, [ign]);

    function getPossessive(word) {
        return word[word.length-1] === 's' ? word + "'" : word + "'s"
    }

    function fetchData() {
        document.title = "Loading... | Notifly.zone";
        axios.get("/api/quests/player/" + ign)
            .then((response) => {
                if (response.data.success) {
                    setQuestData(response.data.data);
                    document.title = getPossessive(response.data.data.name) + " Quests | Notifly.zone";
                } else {
                    setError(response.data.cause);
                    history.push("/")
                }
            })
            .catch((error) => {
                document.title = "Notifly.zone";
            })
    }

    /**
     * Content to display whilst the data is loading
     *
     * @returns {*}
     */
    function loadingScreen() {
        return (
            <div className={classes.loading}>
                <Typography variant={"h4"} className={classes.load}>Loading...</Typography>
                <FontAwesomeIcon icon={faDove} size={"6x"} spin name={"Loading"}/>
            </div>
        )
    }

    /**
     * Content to display when data has finished
     * loading
     * @returns {*}
     */
    function loadContent() {
        return (
            <QuestsDrawer updateChart={() => setChartUpdate(!chartUpdate)} >
                <Switch>
                    <Route path={`${path}/daily`}>
                        <Daily data={questData}/>
                    </Route>
                    <Route path={`${path}/weekly`}>
                        <Weekly data={questData}/>
                    </Route>
                    <Route exact path={`${path}/leaderboard`}>
                        <OverallLeaderboard
                            ign={questData["name"]}
                            uuid={questData["uuid"]}
                            page={"quests"}
                            columns={[{id: "quests", heading: "Quests"}]}
                        />
                    </Route>
                    <Route path={`${path}/leaderboard/games`}>
                        <PerGameLeaderboards
                            ign={questData["name"]}
                            uuid={questData["uuid"]}
                            data={questData["quests"]["daily"]}
                            page={"quests"}
                            columns={[{id: "quests", heading: "Quests"}]}
                        />
                    </Route>
                    <Route path={`${path}/leaderboard/monthly`}>
                        <MonthlyLeaderboard
                            ign={questData["name"]}
                            uuid={questData["uuid"]}
                            data={questData}
                        />
                    </Route>
                    <Route path={`${path}/leaderboard/yearly`}>
                        <YearlyLeaderboard
                            ign={questData["name"]}
                            uuid={questData["uuid"]}
                            data={questData}
                        />
                    </Route>
                    <Route path={`${path}/leaderboard/records`}>
                        <RecordsLeaderboard
                            ign={questData["name"]}
                            uuid={questData["uuid"]}
                            data={questData}
                        />
                    </Route>
                    <Route path={`${path}/calendar`}>
                        <Calendar data={questData}/>
                    </Route>
                    <Route>
                        <QuestsStats update={chartUpdate} data={questData}/>
                    </Route>
                </Switch>
            </QuestsDrawer>
        )
    }

    return (
        Object.keys(questData).length === 0 ? loadingScreen() : loadContent()
    )
}