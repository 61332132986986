import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    padding: {
        [theme.breakpoints.up('xs')]: {
            padding: theme.spacing(1),
        },
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: '100%',
    },
    center: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }
}));

export default function InfoCard(props) {
    const classes = useStyles();

    return (
        <Grid item xs={props.xsCols} sm={props.smCols} md={props.mdCols} lg={props.lgCols}>
            <Paper className={`${props.center ? classes.center : ""} ${props.padding ? classes.padding : ""} ${classes.paper}`}>
                {props.text}
            </Paper>
        </Grid>
    )
}