import FadeIn from 'react-fade-in';
import React, {useContext} from "react";
import makeStyles from '@mui/styles/makeStyles';
import {useTheme} from "@mui/material";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import DeleteIcon from '@mui/icons-material/Delete';
import { withStyles } from '@mui/styles';
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import Chip from "@mui/material/Chip";
import HoverChip from "../HoverChip";
import {SettingsContext} from "../../common/SettingsContext";
import IconButton from '@mui/material/IconButton';
import Grid from "@mui/material/Grid";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControlLabel from '@mui/material/FormControlLabel';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { useSnackbar } from 'notistack';
import { BookmarkSnackbar } from '../BookmarkSnackbar';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    infoCards: {
        marginTop: theme.spacing(1.5),
        marginLeft: theme.spacing(0.75),
        marginRight: theme.spacing(0.75),
    },
    vertical: {
        display: 'grid',
    },
    chip: {
        marginBottom: theme.spacing(2)
    },
    width: {
        width: '100%'
    },
    italic: {
        fontWeight: theme.typography.fontWeightLight,
    },
    materialIcons: {
        display: 'inlineFlex',
        verticalAlign: 'bottom',
    },
    alignMid: {
        display: 'inlineBlock',
        verticalAlign: 'middle',
    },
    tableTitle: {
        padding: theme.spacing(5, 0, 2, 0)
    },
    paper: {
        padding: theme.spacing(2, 0, 2, 0),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(2)
    },
    bookmarkCell: {
        paddingLeft: theme.spacing(1)
    },
    bookmarkButton: {
        marginRight: theme.spacing(1)
    },
    notFoundtext: {
        width: '100%'
    },
    tableHeading: {
        display: "flex",
        justifyContent: "space-between"
    },
    trendingIcon: {
        margin: theme.spacing(0, 2, 0, 2)
    },
    accordionColumn: {
        flexBasis: '33.33%',
    },
}));

export default function BookmarkedAchievements(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    
    const { settings, toggleBookmark, isBookmarked } = useContext(SettingsContext);

    function handleBookmarkClick(game, category, type, achievement) {
        if (isBookmarked(game, category, type, achievement))
            enqueueSnackbar("", {
                variant: "info",
                content: <BookmarkSnackbar
                            type={"info"}
                            icon={"remove"}
                            message={`Removed ${achievement} from Bookmarks`}
                        />
            });
        else
            enqueueSnackbar("", {
                variant: "info",
                content: <BookmarkSnackbar
                            type={"info"}
                            icon={"add"}
                            message={`Added ${achievement} to Bookmarks`}
                        /> 
            });
        
        toggleBookmark(game, category, type, achievement);
    }

    const StyledTableHeader = withStyles((theme) => ({
        root: {
            borderBottomStyle: 'solid',
            borderColor: theme.palette.text.disabled,
            borderWidth: 2
        },
    }))(TableRow);

    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }))(TableRow);

    function getGameTiered(gameKey, categoryKey, achievements) {
        return (
            achievements.map((achievement) => (
                <StyledTableRow key={achievement}>
                    <TableCell className={classes.bookmarkCell}>
                        <IconButton
                            className={classes.bookmarkButton}
                            onClick={() => handleBookmarkClick(gameKey, categoryKey, "tiered", achievement)}
                        >
                            <DeleteIcon />
                        </IconButton>
                        {props.achievements[categoryKey][gameKey]["tiered"][achievement]["name"]}
                    </TableCell>
                    <TableCell>
                        {props.achievements[categoryKey][gameKey]["tiered"][achievement]["description"].split('%s')[0]}
                        <Chip
                            size="small"
                            label={
                                Math.min(props.achievements[categoryKey][gameKey]["tiered"][achievement]["next_goal"], props.achievements[categoryKey][gameKey]["tiered"][achievement]["progress"])
                                + "/" +
                                props.achievements[categoryKey][gameKey]["tiered"][achievement]["next_goal"]
                            }
                        />
                        {props.achievements[categoryKey][gameKey]["tiered"][achievement]["description"].split('%s')[1]}
                    </TableCell>
                    <TableCell className={classes.points}>
                        {props.achievements[categoryKey][gameKey]["tiered"][achievement]["tiers"].map((tier) => {
                            return (
                                <HoverChip
                                    tier={tier}
                                />
                            )
                        })}
                    </TableCell>
                </StyledTableRow>
            ))
        )
    }

    function getGameOneTime(gameKey, categoryKey, achievements) {
        return (
            achievements.map((achievement) =>
                <StyledTableRow key={achievement}>
                    <TableCell className={classes.bookmarkCell}>
                        <IconButton className={classes.bookmarkButton} onClick={() => handleBookmarkClick(gameKey, categoryKey, "one_time", achievement)}>
                            <DeleteIcon />
                        </IconButton>
                        {props.achievements[categoryKey][gameKey]["one_time"][achievement]["name"]}
                    </TableCell>
                    <TableCell>
                        {props.achievements[categoryKey][gameKey]["one_time"][achievement]["description"]}
                    </TableCell>
                    <TableCell>
                        <Chip
                            size="small"
                            style={{
                                color: "white",
                                backgroundColor: `${props.achievements[categoryKey][gameKey]["one_time"][achievement]["completed"]
                                    ? theme.palette.success.main
                                    : theme.palette.error.main}`
                                }}
                            label={props.achievements[categoryKey][gameKey]["one_time"][achievement]["points"]}
                        />
                    </TableCell>
                </StyledTableRow>
            )
        )
    }

    function getGameTable(gameKey, categoryKey, gameObject) {
        return (
            <div>
                <TableContainer>
                    <Table size={"small"}>
                        <TableHead>
                            <StyledTableHeader>
                                <TableCell align="left">
                                    {"Name"}
                                </TableCell>
                                <TableCell align="left">
                                    {"Description"}
                                </TableCell>
                                <TableCell align="left">
                                    {"Points"}
                                </TableCell>
                            </StyledTableHeader>
                        </TableHead>
                        <TableBody>
                            {gameObject.hasOwnProperty("tiered") && getGameTiered(gameKey, categoryKey, Object.keys(gameObject["tiered"]))}
                            {gameObject.hasOwnProperty("one_time") && getGameOneTime(gameKey, categoryKey, Object.keys(gameObject["one_time"]))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        )
    }

    function calculateProgress(data, bookmarked) {
        let totalPoints = 0;
        let earnedPoints = 0;

        if (bookmarked.hasOwnProperty("one_time")) {
            Object.keys(bookmarked.one_time).forEach((id) => {
                totalPoints += data.one_time[id].points;
                if (data.one_time[id].completed) {
                    earnedPoints += data.one_time[id].points
                }
            });
        }

        if (bookmarked.hasOwnProperty("tiered")) {
            Object.keys(bookmarked.tiered).forEach((id) => {
                for (let i=0; i<data.tiered[id].tiers.length; i++) {
                    if (i<Math.floor(data.tiered[id].tiers_completed)) earnedPoints += data.tiered[id].tiers[i].points;
                    totalPoints += data.tiered[id].tiers[i].points
                }
            });
        }
        
        return `${earnedPoints} / ${totalPoints} points earned`
    }

    function renderGameCard(gameKey, categoryKey, gameObject) {
        return (
            <Grid item xs={12}>
                <div className={classes.tableHeading}>
                    <Typography
                        className={classes.tableTitle}
                        align={"left"}
                        variant={"h5"}
                    >
                        {props.achievements[categoryKey][gameKey].name}
                    </Typography>
                    <Typography
                        className={classes.tableTitle}
                        align={"right"}
                        variant={"button"}
                    >
                        {calculateProgress(props.achievements[categoryKey][gameKey], gameObject)}
                    </Typography>
                </div>
                <Paper key={gameKey} className={classes.itemPaper}>
                    {getGameTable(gameKey, categoryKey, gameObject)}
                </Paper>
            </Grid>
        )
    }

    function renderMilestones() {

        let totalAchievements = 0;
        let totalPoints = 0;
        let earnedAchievements = 0;
        let earnedPoints = 0;

        Object.keys(settings.bookmarks).map((categoryKey) =>
            Object.keys(settings.bookmarks[categoryKey]).map((gameKey) => {
                if (settings.bookmarks[categoryKey][gameKey].hasOwnProperty("one_time")) {
                    Object.keys(settings.bookmarks[categoryKey][gameKey].one_time).map((id) => {
                        totalPoints += props.achievements[categoryKey][gameKey].one_time[id].points;
                        totalAchievements++;
                        if (props.achievements[categoryKey][gameKey].one_time[id].completed) {
                            earnedPoints += props.achievements[categoryKey][gameKey].one_time[id].points;
                            earnedAchievements++;
                        }
                    });
                }
                if (settings.bookmarks[categoryKey][gameKey].hasOwnProperty("tiered")) {
                    Object.keys(settings.bookmarks[categoryKey][gameKey].tiered).map((id) => {
                        for (let i=0; i<props.achievements[categoryKey][gameKey].tiered[id].tiers.length; i++) {
                            if (i<Math.floor(props.achievements[categoryKey][gameKey].tiered[id].tiers_completed)) {
                                earnedPoints += props.achievements[categoryKey][gameKey].tiered[id].tiers[i].points;
                                earnedAchievements++;
                            }
                            totalPoints += props.achievements[categoryKey][gameKey].tiered[id].tiers[i].points
                            totalAchievements++;
                        }
                    });
                }
            })
        )

        return (
            <div>
                <Typography color="textSecondary">
                    {"You have completed "}
                    <Chip size="small" label={earnedAchievements + " / " + totalAchievements} />
                    {" bookmarked achievements, earning "}
                    <Chip size="small" label={"+"+earnedPoints} />
                    {" achievement points. Completing the remaining "}
                    <Chip size="small" label={totalAchievements - earnedAchievements} />
                    {" achievements, will earn you an additional "}
                    <Chip size="small" label={"+"+(totalPoints - earnedPoints)} />
                    {" points, reaching a total of "}
                    <Chip size="small" color="primary" label={props.achievementPoints + totalPoints - earnedPoints} />
                    {" achievement points."}
                </Typography>
            </div>
        )
    }

    function renderCards() {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Accordion defaultExpanded>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <FormControlLabel
                                control={<TrendingUpIcon className={classes.trendingIcon} />}
                                label="Achievement Milestone Progress"
                            />
                        </AccordionSummary>
                        <AccordionDetails>
                            {renderMilestones()}
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                {Object.keys(settings.bookmarks).map((categoryKey) =>
                    Object.keys(settings.bookmarks[categoryKey]).map((gameKey) =>
                        renderGameCard(gameKey, categoryKey, settings.bookmarks[categoryKey][gameKey])
                    )
                )}
            </Grid>
        ) 
    }

    return (
        <FadeIn>
            <div className={classes.root}>
                <div className={classes.infoCards}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <Typography variant={"h4"}>{"Bookmarked Achievements"}</Typography>
                            </Paper>
                        </Grid>
                        {Object.keys(settings.bookmarks).length === 0
                            ?   <div className={classes.notFoundtext}>
                                    <Typography align={"center"}>
                                        {"No bookmarks found. To bookmark an achievement, " +
                                        "go to the achievements list and click the bookmark icon next to an achievement. " +
                                        "Bookmarks are saved to your browser and are not associated to individual accounts."}
                                    </Typography>
                                </div>
                            :   renderCards()
                        }
                    </Grid>
                </div>
            </div>
        </FadeIn>
    )
}
