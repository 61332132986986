import { forwardRef } from 'react';
import Button from "@mui/material/Button";
import {SettingsContext} from "./SettingsContext";
import {Brightness4} from "@mui/icons-material";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

export const IndividualThemeButton = forwardRef((props, ref) => (
    <SettingsContext.Consumer>
        {({settings, toggleTheme}) => (
            <Button ref={ref} variant={"contained"} onClick={toggleTheme} style={{minWidth: 150}} startIcon={<Brightness4 />}>
                {settings["theme"]["dark"] ? "Light Mode" : "Dark Mode"}
            </Button>
        )}
    </SettingsContext.Consumer>
));

export function CustomThemeButton() {
    return (
        <SettingsContext.Consumer>
            {({settings, toggleTheme}) => (
                <ListItem button key={settings["theme"]["dark"] ? "Dark Mode" : "Light Mode"} onClick={toggleTheme}>
                    <ListItemIcon><Brightness4/></ListItemIcon>
                    <ListItemText primary={settings["theme"]["dark"] ? "Light Mode" : "Dark Mode"} />
                </ListItem>
            )}
        </SettingsContext.Consumer>
    );
}