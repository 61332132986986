import React, {useEffect} from "react";
import makeStyles from '@mui/styles/makeStyles';
import {Paper} from "@mui/material";
import FadeIn from "react-fade-in";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    maxed: {
        margin: theme.spacing(1, 0, 1, 0)
    },
    fullWidth: {
        width: '100%'
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    inlineRight: {
        margin: 0
    },
    inlineLeft: {
        margin: 0,
        flexGrow: 1
    },
    link: {
        textDecoration: "none",
        '&:hover': {
            textDecoration: "underline",
        },
        color: theme.palette.text.primary
    }
}));

export default function AchievementsPerGameLeaderboards(props) {
    const classes = useStyles();

    useEffect(() => {
        props.setOffset(props.data.filter((entry) => (entry["points"] === props.max)).length);
    },[]);

    function getNames() {
        return props.data.filter((entry) =>
            (entry["points"] === props.max
                && (props.searchText === "" || entry["ign"].toUpperCase().includes(props.searchText.toUpperCase())))).sort((a,b) => (
            a.ign.toUpperCase() > b.ign.toUpperCase()) ? 1 : ((b.ign.toUpperCase() > a.ign.toUpperCase()) ? -1 : 0
        ));
    }

    function showLoading() {
        return (
            <FadeIn>
                <div className={classes.loading}>
                    <p>Loading...</p>
                </div>
            </FadeIn>
        )
    }

    function showTable() {
        const names = getNames();
        return (
            <FadeIn>
                <div className={classes.maxed}>
                    <Alert variant="outlined" severity="success" classes={{message: classes.fullWidth}}>
                        <AlertTitle>
                            <div className={classes.title}>
                                <p className={classes.inlineLeft} align={'left'}>{"Maxed " + props.game}</p>
                                <p className={classes.inlineRight} align={'right'}>{`${props.max}/${props.max}`}</p>
                            </div>
                        </AlertTitle>
                        <Grid container spacing={1}>
                            {names.length === 0 && "No one has maxed " + props.game}
                            {names.map((name) => (
                                <Grid item>
                                    <Paper>
                                        <Link to={`/${props.page}/${name.uuid}/`} className={classes.link}>
                                            {name.ign}
                                        </Link>
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>
                    </Alert>
                </div>
            </FadeIn>
        )
    }

    return (
        Object.keys(props.data).length === 0 ? showLoading() : showTable()
    )
}