import React, {useContext, useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from "@mui/material/AccordionDetails";
import IndividualObjective from "./InvididualObjective";
import List from "@mui/material/List";
import FadeIn from "react-fade-in";
import {ColorLinearProgress} from "../../../common/ColorLinearProgress";
import {SettingsContext} from "../../../common/SettingsContext";
import {useTheme} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    objectives: {
        padding: theme.spacing(0.5),
        textAlign: 'left',
        color: theme.palette.text.secondary,
        display: 'block',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        textAlign: 'left',
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    panel: {
        backgroundColor: theme.palette.background.default,
    },
    info: {
        width: '100%',
        display: 'flow-root'
    },
    progress: {
        width: '100%'
    }
}));

export default function IndividualQuest(props) {
    const classes = useStyles();
    const {settings} = useContext(SettingsContext);
    const theme = useTheme();
    const [colour, setColour] = useState({});

    useEffect(() => {
        setColour(getColour())
    },[theme, props.quest])

    function getColour() {
        const percentage = props.quest.status.progress;
        if (percentage === 100) return {'--progress': theme.palette.success.main,
            '--progress-bg': theme.palette.success.main + "33"};
        else if (percentage < 25) return {'--progress': theme.palette.error.main,
            '--progress-bg': theme.palette.error.main + "33"};
        else if (percentage >= 75) return {'--progress': "#8bc34a",
            '--progress-bg': "#8bc34a" + "33"};
        else return {'--progress': theme.palette.warning.main,
                '--progress-bg': theme.palette.warning.main + "33"};
    }

    function renderProgress() {
        if (settings["quests"]["progress"])
            return (
                <FadeIn>
                    <ColorLinearProgress
                        className={classes.progress}
                        style={colour}
                        variant="determinate"
                        value={props.quest.status.progress}
                    />
                </FadeIn>
            )
    }

    return (
        <Accordion
            expanded={props.expanded}
            className={classes.panel}
            TransitionProps={{ unmountOnExit: true }}
            onChange={() => props.toggleAccordion(props.quest.id)}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{color: colour['--progress']}}/>}
                aria-controls={`${props.quest.id}-content`}
                id={`${props.quest.id}-header`}
            >
                <div className={classes.info}>
                    <Typography className={classes.heading}>{props.quest.name}</Typography>
                    {renderProgress()}
                </div>
            </AccordionSummary>
            <AccordionDetails className={classes.objectives}>
                <List dense={true}>
                    {props.quest.status.objectives.map((objective) => (
                        <IndividualObjective key={objective.id} objective={objective}/>
                    ))}
                </List>
            </AccordionDetails>
        </Accordion>
    )
}