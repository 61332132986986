import React from "react";
import FadeIn from 'react-fade-in';
import QuestsGrid from "../QuestsGrid";
import NameTitle from "../../../../common/NameTitle";

export default function Daily(props) {
    return (
        <FadeIn>
            <NameTitle colourName={props.data["colour_name"]} title={"Daily Quests"} margin={false}/>
            <QuestsGrid today={props.data["today"]} type={"dailies"} quests={props.data["quests"]["daily"]} orderedGames={props.data["sorted_game_ids"]}/>
        </FadeIn>
    )
}