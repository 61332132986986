import React, {useEffect, useState, useContext} from 'react';
import '../home/Home.css';
import { useParams, Route, Switch, useRouteMatch, useHistory } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import AchievementsDrawer from "./AchievementsDrawer";
import AchievementsList from "./AchievementsList";
import PerGameLeaderboards from "../common/Leaderboards/PerGameLeaderboards";
import MaxedLeaderboard from "./Leaderboards/MaxedLeaderboard";
import OverallLeaderboard from "../common/Leaderboards/OverallLeaderboard";
import AchievementsStats from "./Stats/AchievementsStats";
import LegacyAchievements from "./Legacy/LegacyAchievements";
import BookmarkedAchievements from "./Bookmarks/BookmarkedAchievements";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDove} from "@fortawesome/free-solid-svg-icons/faDove";
import axios from "axios";
import { DataContext } from "../common/DataContext";

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: 10,
        padding: 0
    },
    loading: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '15%'
    },
    load: {
        marginBottom: theme.spacing(2)
    }
}));

export default function Achievements(props) {
    const [chartUpdate, setChartUpdate] = useState(false);
    const { path } = useRouteMatch();

    const { setError, setAchievementData, achievementData, genericData } = useContext(DataContext);
    const history = useHistory();

    const {ign} = useParams();
    const classes = useStyles();

    useEffect(() => {
        if (Object.keys(achievementData).length === 0
            || !genericData?.hasOwnProperty("name")
            || !genericData?.hasOwnProperty("uuid")
            || (ign !== genericData.uuid
                && ign.toLowerCase() !== genericData.name.toLowerCase())) {
            setAchievementData({});
            fetchData();
        }
    }, [ign]);

    function getPossessive(word) {
        return word[word.length-1] === 's' ? word + "'" : word + "'s"
    }

    function fetchData() {
        document.title = "Loading... | Notifly.zone";
        axios.get("/api/achievements/player/" + ign)
            .then(response => {
                if (response.data.success) {
                    setAchievementData(response.data.data);
                    document.title = getPossessive(response.data.data.name) + " Achievements | Notifly.zone";
                } else {
                    setError(response.data.cause);
                    history.push("/");
                }
            })
            .catch(error => {
                document.title = "Notifly.zone";
            })
    }

    /**
     * Content to display whilst the data is loading
     *
     * @returns {*}
     */
    function loadingScreen() {
        return (
            <div className={classes.loading}>
                <Typography variant={"h4"} className={classes.load}>Loading...</Typography>
                <FontAwesomeIcon icon={faDove} size={"6x"} spin name={"Loading"}/>
            </div>
        )
    }

    /**
     * Content to display when data has finished
     * loading
     * @returns {*}
     */
    function loadContent() {

        return (
            <AchievementsDrawer updateChart={() => setChartUpdate(!chartUpdate)} data={achievementData}>
                <Switch>
                    {Object.keys(achievementData["achievements"]["normal"]).filter((game) => achievementData["achievements"]["normal"][game]["legacy"] === false).map((game) => (
                        <Route key={game} exact path={`${path}/${game}`}>
                            <AchievementsList
                                category={"normal"}
                                game={game}
                                stats={achievementData["games"][game]}
                                achievements={achievementData["achievements"]["normal"][game]}
                            />
                        </Route>
                    ))}
                    {Object.keys(achievementData["achievements"]["special"]).filter((game) => achievementData["achievements"]["special"][game]["legacy"] === false).map((game) => (
                        <Route key={game} exact path={`${path}/${game}`}>
                            <AchievementsList
                                category={"special"}
                                game={game}
                                stats={achievementData["games"][game]}
                                achievements={achievementData["achievements"]["special"][game]}
                            />
                        </Route>
                    ))}
                    <Route path={`${path}/bookmarks`}>
                        <BookmarkedAchievements achievements={achievementData.achievements} achievementPoints={achievementData.stats.achievements.earned_points}/>
                    </Route>
                    <Route path={`${path}/legacy`}>
                        <LegacyAchievements data={achievementData}/>
                    </Route>
                    <Route path={`${path}/leaderboard/games`}>
                        <PerGameLeaderboards ign={achievementData["name"]} uuid={achievementData["uuid"]} page={"achievements"} data={achievementData["achievements"]} columns={[{id: "points", heading: "Points"}, {id: "achievements", heading: "Achievements"}]}/>
                    </Route>
                    <Route path={`${path}/leaderboard/maxed`}>
                        <MaxedLeaderboard ign={achievementData["name"]} uuid={achievementData["uuid"]} page={"achievements"} data={achievementData["achievements"]} columns={[{id: "maxed", heading: "Maxed Games"}]}/>
                    </Route>
                    <Route path={`${path}/leaderboard`}>
                        <OverallLeaderboard ign={achievementData["name"]} uuid={achievementData["uuid"]} page={"achievements"} data={achievementData["achievements"]} columns={[{id: "points", heading: "Points"}, {id: "achievements", heading: "Achievements"}]}/>
                    </Route>
                    <Route>
                        <AchievementsStats update={chartUpdate} data={achievementData}/>
                    </Route>
                </Switch>
            </AchievementsDrawer>
        )
    }

    return (
        Object.keys(achievementData).length === 0 ? loadingScreen() : loadContent()
    )
}