import React, { useState, useEffect } from "react";
import FadeIn from 'react-fade-in';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';

import './main.css';

export default function Calendar(props) {

    const [data, setData] = useState([]);

    useEffect(() => {
        let tmp = [];
        for (let quest of props.data.calendar) {
            quest.end = quest.start+1;
            tmp.push(quest);
        }
        setData(tmp);
    }, [props.data.calendar])

    return (
        <FadeIn>
            <FullCalendar
                initialView={"dayGridMonth"}
                events={data}
                headerToolbar={{
                    left: 'title',
                    center: 'dayGridMonth,dayGridWeek',
                    right: 'prevYear,prev,today,next,nextYear'
                }}
                nowIndicator={true}
                views={{
                    dayGridMonth: {
                        dayMaxEvents: 7
                    },
                }}
                height={"auto"}
                contentHeight={'100%'}
                showNonCurrentDates={false}
                eventTimeFormat={{
                    hour12: false,
                    hour: '2-digit',
                    minute: '2-digit',
                }}
                firstDay={1}
                plugins={[dayGridPlugin]}
            />
        </FadeIn>
    )
}