import React, {useState} from "react";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import Chip from "@mui/material/Chip";
import Collapse from "@mui/material/Collapse";
import Tooltip from "@mui/material/Tooltip";
import {ColorLinearProgress} from "./ColorLinearProgress";
import useTheme from "@mui/material/styles/useTheme";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    networkProgress: {
        width: '100%',
        marginTop: theme.spacing(1)
    },
    link: {
        cursor: 'pointer'
    },
    width: {
        width: '100%'
    },
    alignMid: {
        display: 'inlineBlock',
        verticalAlign: 'middle',
    },
    materialIcons: {
        display: 'inlineFlex',
        verticalAlign: 'bottom',
    },
}));


export default function NetworkLevel(props) {
    const theme = useTheme();
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    return (
        <div className={classes.width} onClick={() => setOpen(!open)}>
            <span className={classes.alignMid}>
                {open ? <ExpandLess className={classes.materialIcons}/> : <ExpandMore className={classes.materialIcons}/>}
                <span className={classes.link}>{"Network Level "}</span>
            </span>
            <Chip size={"small"} label={<span className={classes.link}>{props.data.current_level}</span>}/>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Tooltip arrow title={Math.floor(props.data["percentage_to_next_level"]*100) + "% progress to level " + (props.data.current_level+1) + ", that's " + props.data.exp_to_next_level.toLocaleString() +" exp"}>
                    <ColorLinearProgress
                        className={classes.networkProgress}
                        style={{
                            "--progress": theme.palette.primary.main,
                            "--progress-bg": theme.palette.primary.main + "33"
                        }}
                        variant="determinate"
                        value={props.data["percentage_to_next_level"]*100}
                    />
                </Tooltip>
            </Collapse>
        </div>
    )
}