import React, {useState} from "react";
import makeStyles from '@mui/styles/makeStyles';
import Chip from "@mui/material/Chip";
import {useTheme} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    point: {
        margin: theme.spacing(0.5),
    },
}));

export default function HoverChip(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = useState(props.tier["points"]);

    return (
        <Chip
            key={props.tier["tier"]}
            size="small"
            className={classes.point}
            style={{color: "white", backgroundColor: `${props.tier["completed"] === 1 ? theme.palette.success.main : props.tier["completed"] === 0 ? theme.palette.warning.main : theme.palette.error.main}`}}
            label={value}
            onMouseEnter={() => setValue(props.tier["amount"])}
            onMouseLeave={() => setValue(props.tier["points"])}
        />
    )
}
