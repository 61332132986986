import {Paper, useTheme} from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { Link } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import React, {useEffect, useState} from "react";
import { withStyles } from '@mui/styles';
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import FadeIn from "react-fade-in";
import makeStyles from '@mui/styles/makeStyles';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Alert from '@mui/material/Alert';
import {EmojiEvents} from "@mui/icons-material";
import AchievementsPerGameLeaderboards from "../../achievements/Leaderboards/AchievementsPerGameLeaderboards";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDove} from "@fortawesome/free-solid-svg-icons/faDove";
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    bold: {
        fontWeight: theme.typography.fontWeightBold
    },
    trophy: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    emptySearch: {
        textAlign: 'center'
    },
    loading: {
        marginBottom: '100%'
    },
    header: {
        margin: theme.spacing(1, 0, 1, 0)
    },
    position: {
        width: 'auto',
        borderColor: theme.palette.primary.main,
        color: theme.palette.text.secondary,
        display: "inline-flex"
    },
    grow: {
        flexGrow: 1
    },
    link: {
        textDecoration: "none",
        '&:hover': {
            textDecoration: "underline",
        },
        color: theme.palette.text.primary
    },
}));

export default function Leaderboard(props) {
    const classes = useStyles();
    const [searchText, setSearchText] = useState("");
    const [data, setData] = useState({});
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        getData();
    }, [props.leaderboard, props.ign])

    function getData() {
        setData({});
        axios.get(`/api/${props.page}/leaderboards/${props.leaderboard}?uuid=${props.uuid}`)
            .then(response => {
                setData(response.data.data)
            })
    }

    function showLoading() {
        return (
            <FadeIn>
                <div className={classes.loading}>
                    <span><FontAwesomeIcon icon={faDove} size={"lg"} spin name={"Loading"}/> Loading...</span>
                </div>
            </FadeIn>
        )
    }

    function handleSearchTextChange(text) {
        setSearchText(text)
    }

    function showTable() {
        return (
            <div>
                <SearchLeaderboard text={handleSearchTextChange} placement_text={data["placement_text"]}>
                    {props.children}
                </SearchLeaderboard>
                {props.max !== undefined && <AchievementsPerGameLeaderboards page={props.page} game={props.getGameDisplayName(props.leaderboard)} setOffset={(num) => setOffset(num)} max={props.max} data={data["leaderboard"]} searchText={searchText}/>}
                <NumberedLeaderboard offset={offset} data={data} page={props.page} columns={props.columns} searchText={searchText}/>
            </div>
        )
    }

    return (
        Object.keys(data).length === 0 ? showLoading() : showTable()
    )
}

function NumberedLeaderboard(props) {
    const classes = useStyles();

    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }))(TableRow);

    function getPosition(index) {
        switch (index+1+props.offset) {
            case 1:
                return (<span className={classes.trophy}><EmojiEventsIcon style={{color: "gold"}}/>{index+1}</span>)
            case 2:
                return (<span className={classes.trophy}><EmojiEventsIcon style={{color: "silver"}}/>{index+1}</span>)
            case 3:
                return (<span className={classes.trophy}><EmojiEventsIcon style={{color: "#cd7f32"}}/>{index+1}</span>)
            default:
                return (index+1+props.offset)
        }
    }

    /**
     * Get the entries of the table, taking
     * into account the search term
     * @returns {*} entries or empty message
     */
    function getEntries() {
        let emptySearch = true;
        const entries = (
            props.data["leaderboard"].slice(props.offset).map((row, index, arr) => {
                if (props.searchText === "" || row["ign"].toUpperCase().includes(props.searchText.toUpperCase())) {
                    emptySearch = false;
                    return (
                        <StyledTableRow key={row.index}>
                            <TableCell>{getPosition(index)}</TableCell>
                            <TableCell component="th" scope="row">
                                <Link to={`/${props.page}/${row.uuid}/`} className={classes.link}>
                                    {row.ign}
                                </Link>
                            </TableCell>
                            {props.columns.map((column) => (
                                <TableCell key={column.id}>{row[column.id]}</TableCell>
                            ))}
                        </StyledTableRow>
                    )
                }
            })
        )
        if (emptySearch) return (
            <TableCell colSpan={10} className={classes.emptySearch}>
                {`No players found in the top 250, try again`}
            </TableCell>);
        else return entries
    }

    return (
        <LeaderboardTable entries={getEntries()} columns={props.columns}/>
    )
}

function LeaderboardTable(props) {
    const classes = useStyles();

    const StyledTableHeader = withStyles((theme) => ({
        root: {
            borderBottomStyle: 'solid',
            borderColor: theme.palette.text.disabled,
            borderWidth: 2
        },
    }))(TableRow);

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table}>
                <TableHead className={classes.bold}>
                    <StyledTableHeader>
                        <TableCell>#</TableCell>
                        <TableCell>Username</TableCell>
                        {props.columns.map((column) => (
                            <TableCell key={column.id}>{column.heading}</TableCell>
                        ))}
                    </StyledTableHeader>
                </TableHead>
                <TableBody>
                    {props.entries}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

function SearchLeaderboard(props) {
    const classes = useStyles();
    const theme = useTheme();

    function handleChange(event) {
        props.text(event.target.value)
    }

    return (
        <Grid container spacing={1} className={classes.header}>
            <Grid item xs={props.children ? 4 : 12}>
                {props.children}
            </Grid>
            <Grid item xs={12} md>
                <TextField
                    className={classes.search}
                    id="standard-full-width"
                    placeholder="Username"
                    helperText="Search leaderboard"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                    onChange={handleChange}
                    color={"primary"}
                />
            </Grid>
            <Grid item sm={12} md>
                <Typography align="right">
                    <Alert
                        variant={"outlined"}
                        icon={<EmojiEvents fontSize="inherit" color={"primary"} />}
                        className={classes.position}
                    >
                        {props.placement_text}
                    </Alert>
                </Typography>
            </Grid>
        </Grid>
    )
}