import FadeIn from 'react-fade-in';
import React, {useEffect, useState} from "react";
import makeStyles from '@mui/styles/makeStyles';
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import PerGamePie from "../../common/PerGamePie";
import PerGameTable from "../../common/PerGameTable";
import {Button, useTheme} from "@mui/material";
import {ColorLinearProgress} from "../../common/ColorLinearProgress";
import NameTitle from "../../common/NameTitle";
import InfoCard from "../../common/InfoCard";
import Typography from "@mui/material/Typography";
import NetworkLevel from "../../common/NetworkLevel";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableFooter from "@mui/material/TableFooter";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    infoCards: {
        marginTop: theme.spacing(1.5),
        marginLeft: theme.spacing(0.75),
        marginRight: theme.spacing(0.75),
    },
    vertical: {
        display: 'grid',
    },
    chip: {
        marginBottom: theme.spacing(2)
    },
    width: {
        width: '100%'
    },
    italic: {
        fontWeight: theme.typography.fontWeightLight,
    },
    materialIcons: {
        display: 'inlineFlex',
        verticalAlign: 'bottom',
    },
    alignMid: {
        display: 'inlineBlock',
        verticalAlign: 'middle',
    }
}));

export default function AchievementsStats(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [oneTimeRows, setOneTimeRows] = useState(5);

    function getQuests() {
        return (
            <div>
                {"Quests "}
                <Chip size={"small"} label={props.data["stats"]["quests"]}/>
            </div>
        )
    }

    function getNetworkLevel() {
        return (
            <NetworkLevel data={props.data["network_level"]}/>
        )
    }

    function getAchievementPoints() {
        return (
            <div>
                {"Achievement Points "}
                <Chip size={"small"} label={props.data["stats"]["achievements"]["earned_points"]}/>
            </div>
        )
    }

    function getAchievementsPerGamePie() {
        return (
            <PerGamePie value={"value2"} update={props.update} data={props.data["stats"]["games"]}/>
        )
    }

    function getAchievementsPerGameTable() {
        return (
            <PerGameTable valueHeader={"Progress"} valueData={"value1"} data={props.data["stats"]["games"]}/>
        )
    }

    function getCompletionProgressBar(title1, achievement1, title2, achievement2) {
        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    {getCompletionProgressText(achievement1, title1, true)}
                    <Tooltip
                        title={Math.floor((props.data["stats"]["achievements"]["earned_"+achievement1]/props.data["stats"]["achievements"]["total_"+achievement1])*100) + "% " + title1 + " earned"}
                        arrow
                        placement={"top"}
                    >
                        <ColorLinearProgress
                            className={classes.width}
                            style={{
                                "--progress": theme.palette.primary.main,
                                "--progress-bg": theme.palette.primary.main + "33"
                            }}
                            variant="determinate"
                            value={(props.data["stats"]["achievements"]["earned_"+achievement1] / props.data["stats"]["achievements"]["total_"+achievement1])*100}
                        />
                    </Tooltip>
                </Grid>
                <Grid item xs={12}>
                    <Tooltip
                        title={Math.floor((props.data["stats"]["achievements"]["earned_"+achievement2]/props.data["stats"]["achievements"]["total_"+achievement2])*100) + "% " + title2 + " completed"}
                        arrow
                        placement={"bottom"}
                    >
                        <ColorLinearProgress
                            className={classes.width}
                            style={{
                                "--progress": theme.palette.primary.main,
                                "--progress-bg": theme.palette.primary.main + "33"
                            }}
                            variant="determinate"
                            value={(props.data["stats"]["achievements"]["earned_"+achievement2] / props.data["stats"]["achievements"]["total_"+achievement2])*100}
                        />
                    </Tooltip>
                    {getCompletionProgressText(achievement2, title2, false)}
                </Grid>
            </Grid>
        )
    }

    function getCompletionProgressText(stat, title, topLeft) {
        return (
            <div className={classes.width}>
                {topLeft ? getProgressTitle(title, "left") : getProgressValues(props.data["stats"]["achievements"]["earned_" + stat].toLocaleString(),
                    props.data["stats"]["achievements"]["total_" + stat].toLocaleString(), "right")}
                {topLeft ? getProgressValues(props.data["stats"]["achievements"]["earned_" + stat].toLocaleString(),
                    props.data["stats"]["achievements"]["total_" + stat].toLocaleString(), "left") : getProgressTitle(title, "right")}
            </div>
        )
    }

    function getProgressTitle(title, align) {
        return (
            <Typography variant="h6" className={classes.italic} align={align}>
                <i>{title}</i>
            </Typography>
        )
    }

    function getProgressValues(value1, value2, align) {
        return (
            <Typography variant="h3" align={align}>
                <b><i>{`${value1} / ${value2}`}</i></b>
            </Typography>
        )
    }

    function getRecentOneTimeAchievements() {
        return (
            <TableContainer>
                <Typography variant="h6" align={"left"}>
                    {"Recent one time achievements"}
                </Typography>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Game</TableCell>
                            <TableCell>Achievement</TableCell>
                            <TableCell>Points</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.data["latest"]["one_time"].slice(0, oneTimeRows).map((achievement) =>
                            <TableRow row={achievement}>
                                <TableCell>{achievement["game"]}</TableCell>
                                <TableCell>{achievement["name"]}</TableCell>
                                <TableCell>+{achievement["points"]}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableCell colSpan={3} align={"right"}>
                            <Button onClick={() => setOneTimeRows(oneTimeRows === 15 ? 5 : oneTimeRows + 5)}>
                                {oneTimeRows === 15 ? "Show less..." : "Show more..."}
                            </Button>
                        </TableCell>
                    </TableFooter>
                </Table>
            </TableContainer>
        )
    }

    return (
        <FadeIn>
            <div className={classes.root}>
                <div className={classes.infoCards}>
                    <Grid container spacing={2}>
                        <NameTitle colourName={props.data["colour_name"]} title={"Achievement Stats"} margin={false}/>
                        <InfoCard center xsCols={6} mdCols={4} padding text={getQuests()}/>
                        <InfoCard center xsCols={6} mdCols={4} padding text={getNetworkLevel()}/>
                        <InfoCard center xsCols={12} mdCols={4} padding text={getAchievementPoints()}/>
                        <InfoCard center xsCols={12} mdCols={12} padding text={getCompletionProgressBar("Achievement points", "points", "Achievements", "achievements")}/>
                        <InfoCard xsCols={12} mdCols={5} padding text={getAchievementsPerGameTable()}/>
                        <InfoCard xsCols={12} mdCols={7} text={getAchievementsPerGamePie()}/>
                        <InfoCard xsCols={12} mdCols={7} padding text={getRecentOneTimeAchievements()}/>
                    </Grid>
                </div>
            </div>
        </FadeIn>
    )
}
