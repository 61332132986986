import React, {createRef, useEffect, useState} from "react";
import moment from "moment-timezone";
import {
    Chart,
    Series,
    ArgumentAxis,
    Aggregation,
    CommonSeriesSettings,
    CommonAxisSettings,
    Grid as RsGrid,
    Legend,
    Tooltip,
    Label,
    ValueAxis,
    AggregationInterval,
    Crosshair,
    Point
} from 'devextreme-react/chart';
import RangeSelector, {
    Size,
    Scale,
    Chart as RsChart,
    ValueAxis as RsValueAxis,
    Series as RsSeries,
    Aggregation as RsAggregation,
    Behavior,
    SliderMarker
  } from 'devextreme-react/range-selector';
import {useTheme} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import CompareUsers from "./CompareUsers";
import {getColours} from "../../../../App"

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    formControl: {
        minWidth: 60,
        textAlign: 'left',
    },
    buttons: {
        display: 'grid'
    },
    item: {
        alignSelf: 'center'
    }
}));

export default function QuestsPerDaySpline(props) {
    const theme = useTheme();
    const chartRef = createRef();
    const rangeRef = createRef();
    const classes = useStyles();

    const periods = {day: 'day', week: 'week', month: 'month', year: 'year'};
    const types = {cumulative: 'cumulative', absolute: 'absolute'};

    const [period, setPeriod] = useState(periods.month);
    const [type, setType] = useState(types.absolute);

    const [series, setSeries] = useState([]);
    const [data, setData] = useState({});

    const [visualRange, setVisualRange] = useState([props.first, props.last]);
    const [autoShrink, setAutoShrink] = useState(true);

    const colours = orderColours();

    useEffect(() => {
        setTimeout(() => {
            if (chartRef.current != null) chartRef.current.instance.render()
            if (rangeRef.current != null) rangeRef.current.instance.render()
        }, 500);
    }, [props.update])

    useEffect(() => {
        const initialSeries = [{
            'name': props.name,
            'visible': true,
            'value': '0',
            'first': props.first,
            'last': props.last
        }];
        const initialData = {absolute: props.data, cumulative: cumulateData(props.data, initialSeries)};
        setData(initialData);
        setSeries(initialSeries);
    }, [props.data]);

    function cumulateData(absoluteData, newSeries) {

        let new_data = [];
        
        for (let player of newSeries) {
            let cumulative = 0;
            for (let day in absoluteData) {
                cumulative += absoluteData[day][player.value];

                let obj = {};
                for (let key of Object.keys(absoluteData[day])) {
                    obj[key] = absoluteData[day][key];
                }
                obj[player.value] = cumulative;

                new_data.push(obj);
            }
        }

        return new_data;
    }
    
    function toggleSeries(name, newData, first_quest, last_quest) {
        const nameIndex = seriesIndex(name);
        
        const newSeries = [...series];
        if (nameIndex < series.length) newSeries[nameIndex] = { 
            'name': name,
            'visible': !newSeries[nameIndex].visible,
            'value': nameIndex.toString(),
            'first': newSeries[nameIndex].first,
            'last': newSeries[nameIndex].last
        };
        else newSeries.push({
            'name': name,
            'visible': true,
            'value': nameIndex.toString(),
            'first': first_quest,
            'last': last_quest
        });

        if (newData != null) {
            let dataToSet = JSON.parse(JSON.stringify(data.absolute));
            for (let day in dataToSet) {
                dataToSet[day][nameIndex] = newData[day]['0'];
            }
            setData({absolute: dataToSet, cumulative: cumulateData(dataToSet, newSeries)});
        }

        setSeries(newSeries);
    }

    function seriesIndex(name) {
        for (let i in series)
            if (series[i].name === name)
                return i;
        return series.length;
    }

    function handleDelete(name) {
        const nameIndex = seriesIndex(name);
        const newSeries = [...series];
        newSeries.splice(nameIndex, 1);

        let dataToSet = JSON.parse(JSON.stringify(data.absolute));
        for (let day in dataToSet) delete dataToSet[day][nameIndex];

        setData({absolute: dataToSet, cumulative: cumulateData(dataToSet, newSeries)});
        setSeries(newSeries);
    }

    function orderColours() {
        let newColours = getColours();
        while (newColours[0] != theme.palette.primary.main)
            newColours.push(newColours.shift());
        
        return newColours;
    }

    function customizeTooltip(arg) {
        let original = arg.valueText.split('\n');
        let string = "";
        let date = original.pop();
        let ordered = original.sort((a,b) => 
            b.split(' ')[1] - a.split(' ')[1]
        );
        for (let i in ordered) string += ordered[i] + " quests\n";
        string += date;

        return {text: string}
    }

    function getVisualRangeValues(p) {
        let firstFirst = props.first;
        let lastLast = props.last;

        for (let player of series) {
            if (new Date(player.first) < new Date(firstFirst)) firstFirst = player.first;
            if (new Date(player.last) > new Date(lastLast)) lastLast = player.last;
        }

        return [moment(firstFirst).tz('America/New_York').startOf(p), moment(lastLast).tz('America/New_York').startOf(p)]
    }

    function setAccurateVisualRange(p, shrink) {
        if (shrink) setVisualRange(getVisualRangeValues(p));
        else setVisualRange([moment("2016-01-16"), moment(new Date())]);
    }

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={8} lg={9} xl={10} >
                    <Chart
                        ref={chartRef}
                        dataSource={type === types.absolute ? data.absolute : data.cumulative}
                        redrawOnResize={true}
                        onDone={() => setAccurateVisualRange(period, autoShrink)}
                    >
                        <Size width={'50%'}/>
                        <CommonSeriesSettings
                            argumentField="day"
                            type="spline"
                        />
                            <Point hoverMode="allArgumentPoints" />
                        <CommonAxisSettings>
                            <RsGrid visible={false} />
                        </CommonAxisSettings>
                        <ArgumentAxis
                            visualRange={visualRange}
                            valueMarginsEnabled={true}
                            argumentType="datetime"
                            aggregationInterval={period}
                        >
                            <AggregationInterval enabled={true} />
                            <Label
                                rotationAngle={'45'}
                                wordWrap="none"
                                overlappingBehavior={"rotate"}
                            />
                        </ArgumentAxis>
                        {series.map((player) => (
                            <Series
                                key={player}
                                visible={player.visible}
                                valueField={player.value}
                                name={player.name}
                                width={1}
                                point={{ size: 5 }}
                                hoverStyle={{ width: 1 }}
                                color={colours[series.indexOf(player)]}
                            >
                                <Aggregation enabled={true} method={type === types.absolute ? 'sum' : 'max'} />
                        </Series>
                        ))}
                        <Crosshair
                            enabled={true}
                            width={1}
                            color={'gray'}
                            horizontalLine={{visible: false}}
                            verticalLine={{visible: true}}
                            dashStyle={'longDash'}
                        >

                        </Crosshair>
                        <Legend visible={false}/>
                        <ValueAxis valueType="numeric" />
                        <Tooltip shared enabled={true} customizeTooltip={customizeTooltip}/>
                    </Chart>
                    <RangeSelector
                        ref={rangeRef}
                        dataSource={type === types.absolute ? data.absolute : data.cumulative}
                        onValueChanged={(range) => setVisualRange(range.value, autoShrink)}
                        value={{
                            startValue: moment(visualRange[0]).tz('America/New_York').startOf(period),
                            endValue: moment(visualRange[1]).tz('America/New_York').endOf(period)
                        }}
                    >
                        <Size height={120} />
                        <RsChart>
                            <RsValueAxis valueType="numeric" />
                            {series.map((player) => (
                                <RsSeries
                                    key={player}
                                    type="line"
                                    visible={player.visible}
                                    valueField={player.value}
                                    argumentField="day"
                                    color={colours[series.indexOf(player)]}
                                    innerColor={theme.palette.primary.light}
                                >
                                    <RsAggregation enabled="true" />
                                </RsSeries>
                            ))};
                        </RsChart>
                        <Scale
                            placeholderHeight={20}
                            tickInterval={period}
                            valueType="datetime"
                            aggregationInterval={period}
                        />
                        <Behavior
                            snapToTicks={false}
                            callValueChanged="onMoving"
                        />
                        <SliderMarker format="longDate" />
                    </RangeSelector>
                </Grid>
                <Grid item className={classes.item} xs={12} sm={12} md={4} lg={3} xl={2} >
                    <div className={classes.buttons}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="time-unit">Time unit</InputLabel>
                            <Select
                                labelId="time-unit"
                                id="time-unit"
                                value={period}
                                onChange={(p) => {
                                    setPeriod(p.target.value)
                                    setAccurateVisualRange(p.target.value, autoShrink)
                                }}
                            >
                                {Object.keys(periods).map((p) => 
                                    <MenuItem key={p} value={p}>{periods[p].replace(/^\w/, (c) => c.toUpperCase())}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        <FormControlLabel
                            control={<Switch
                                        checked={autoShrink}
                                        color={"primary"}
                                        onChange={() => {
                                            setAutoShrink(!autoShrink);
                                            setAccurateVisualRange(period, !autoShrink)
                                        }}
                                        name="autoShrink"
                                    />}
                            label="Auto shrink"
                        />
                        <FormControlLabel
                            control={<Switch
                                        checked={type === types.cumulative}
                                        color={"primary"}
                                        onChange={() => setType(type === types.cumulative
                                            ? types.absolute
                                            : types.cumulative
                                        )}
                                        name="type"
                                    />}
                            label="Cumulative"
                        />
                        <hr style={{width: '65%'}}/>
                        <CompareUsers
                            colours={colours}
                            name={props.name}
                            toggleSeries={(name, newData, first_quest, last_quest) => toggleSeries(name, newData, first_quest, last_quest)}
                            handleDelete={(name) => handleDelete(name)}
                        />
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}
