import React, {useContext, useEffect, useState} from 'react';
import clsx from 'clsx';
import { useTheme, styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {Button} from "@mui/material";
import {CustomThemeButton} from "./ThemeButtons";
import {Redirect} from "react-router-dom";
import TwitterIcon from "@mui/icons-material/Twitter";
import TopMenu from "./TopMenu";
import {SettingsContext} from "./SettingsContext";

export default function SideDrawer(props) {
    const [drawerWidth, setDrawerWidth] = useState(localStorage.getItem("drawer_width") !== null
        ? localStorage.getItem("drawer_width")
        : 300);

    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
        },
        drawerOpen: {
            width: drawerWidth + "px",
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.spacing(7) + 1,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9) + 1,
            },
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        grow: {
            flexGrow: 1,
        },
        dragger: {
            width: '5px',
            height: '100%',
            cursor: 'ew-resize',
            padding: '4px 0 0',
            borderTop: '1px solid #ddd',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            zIndex: '100',
            backgroundColor: '#f4f7f9'
        },
        width: {
            width: '100%',
        },
        noselect: {
            userSelect: 'none'
        },
        row: {
            display: 'flex'
        },
        icon: {
            flexGrow: 1,
            marginRight: theme.spacing(1)
        }
    }));
    
    const [redirect, setRedirect] = useState(false);
    const classes = useStyles();
    const theme = useTheme();
    const [isResizing, setIsResizing] = useState(false);

    const { settings } = useContext(SettingsContext);


    useEffect(() => {
        if (settings["sidebar"]["resizable"]) {
            function handleMouseMove(e) {
                if (!isResizing || !props.open) return;
                let minWidth = 300;
                let maxWidth = 700;
                if (e.clientX >= minWidth && e.clientX <= maxWidth) {
                    setDrawerWidth(e.clientX)
                    localStorage.setItem("drawer_width", e.clientX);
                }
            }
            function handleMouseUp(e) {
                if (isResizing) {
                    props.updateChart();
                    setIsResizing(false)
                }
            }
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
            return () => {
                document.removeEventListener('mousemove', handleMouseMove);
                document.removeEventListener('mouseup', handleMouseUp)
            }
        }
    }, [isResizing]);

    /**
     * When user clicks on empty space of drawer:
     * - Toggles drawer
     */
    function handleDrawerEmptyClick() {
        props.open ? props.setOpen(false) : props.setOpen(true)
    }

    function handleHomeClick() {
        setRedirect(true)
    }

    function renderRedirect() {
        if (redirect) {
            return <Redirect push to={{
                pathname: "/",
                data: {}
            }}/>
        }
    }

    return (
        <div className={classes.root}>
            <div className={isResizing && classes.noselect}>
                {renderRedirect()}
                <TopMenu
                    drawerWidth={drawerWidth}
                    page={props.page}
                    open={props.open}
                    setOpen={(open) => props.setOpen(open)}
                />
                <Drawer
                    sx={drawerStyles(drawerWidth, props.open, theme)}
                    variant="permanent"
                    anchor="left"
                    ModalProps={{
                        keepMounted: true
                    }}
                >
                    <div
                        onMouseDown={() => setIsResizing(true)}
                        className={props.open && settings["sidebar"]["resizable"] && classes.dragger}
                    />
                    <div className={classes.toolbar}>
                        <Button onClick={handleHomeClick}>Home</Button>
                        <div className={classes.grow}/>
                        <IconButton onClick={() => props.setOpen(false)}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </div>
                    <Divider />
                    {props.children}
                    <Divider />
                    <div className={classes.grow} onClick={handleDrawerEmptyClick}/>
                    <List>
                        <List className={classes.row}>
                            <CustomThemeButton/>
                            <IconButton href={"https://twitter.com/Notifly_"} target={"_blank"} aria-label="delete" className={classes.icon}>
                                <TwitterIcon />
                            </IconButton>
                        </List>
                    </List>
                </Drawer>
            </div>
        </div>
    );
}

const drawerStyles = (drawerWidth, open, theme) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme, drawerWidth),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
})

const openedMixin = (theme, drawerWidth) => ({
    width: drawerWidth || 300,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});
  
const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(9)} + 1px)`,
    },
});