import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import Leaderboard from "./Leaderboard";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    title: {
        [theme.breakpoints.up('xs')]: {
            textAlign: 'center'
        },
        [theme.breakpoints.up('sm')]: {
            textAlign: 'left'
        },
    }
}));

export default function OverallLeaderboard(props) {
    const classes = useStyles();

    return (
        <div>
            <Typography variant={"h4"} className={classes.title}>{"Overall Leaderboard"}</Typography>
            <Leaderboard ign={props.ign} uuid={props.uuid} page={props.page} leaderboard={"overall"} columns={props.columns}/>
        </div>
    )
}