import React from "react";

export const SettingsContext = React.createContext({
    settings: {},
    getColours: () => {},
    toggleTheme: () => {},
    setAccentColour: () => {},
    setPage: () => {},
    toggleStickySidebar: () => {},
    toggleSidebarResizable: () => {},
    setSidebarWidth: () => {},
    handleTypeChange: () => {},
    handleStatusChange: () => {},
    handleSizeChange: () => {},
    handleMetricChange: () => {},
    toggleAchievementProgressColours: () => {},
    toggleQuestsProgress: () => {},
    handleStatusAndTypeChange: () => {},
    toggleHighContrast: () => {},
    toggleBookmark: (game, category, type, achievement) => {},
    isBookmarked: (game, category, type, achievement) => {},
    toggleCompletedQuests: () => {}
});