import {AccountCircle, PeopleAlt} from "@mui/icons-material";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";
import React, { useState, useContext } from "react";
import {useStyles} from "../home/styles";
import axios from "axios";
import Tooltip from "@mui/material/Tooltip";
import { useSnackbar } from 'notistack';
import { DataContext } from "./DataContext";
import { useHistory } from "react-router-dom";

export default function SearchForm(props) {
    const [ign, setIgn] = useState(props.value);
    const [isLoading, setLoading] = useState(false);

    const history = useHistory();

    const { setAchievementData, setQuestData, genericData } = useContext(DataContext);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const classes = useStyles();

    function getPossessive(ign) {
        return ign[ign.length-1] === 's' ? ign + "'" : ign + "'s"
    }

    /**
     * Handles when the user clicks the submit button
     */
    function handleButtonClick() {
        setLoading(true);
        const loadingID = enqueueSnackbar(`Loading '${ign}'...`, { variant: "info", persist: true });
        document.title = "Loading... | Notifly.zone";
        props.handleLoading !== undefined && props.handleLoading(true);
        switch (props.page.toLowerCase()) {
            case 'quests':
                axios.get("/api/quests/player/" + ign)
                    .then ((response) => {
                        closeSnackbar(loadingID);
                        return response;
                    })
                    .then((response) => {
                        if (response.data.success) {
                            document.title = getPossessive(response.data.data["name"]) + " Quests | Notifly.zone";
                            enqueueSnackbar("Success", { variant: "success" });
                            setQuestData(response.data.data);
                            history.push("/quests/"+response.data.data.uuid)
                        } else {
                            document.title = "Notifly.zone";
                            enqueueSnackbar(response.data.cause, { variant: "error"});
                        }
                    })
                    .catch((error) => {
                        document.title = "Notifly.zone";
                        enqueueSnackbar(error.cause, { variant: "error" });
                    })
                    .finally(() => {
                        setLoading(false);
                        props.handleLoading !== undefined && props.handleLoading(false);
                    });
                break;
            case 'achievements':
                axios.get("/api/achievements/player/" + ign)
                    .then ((response) => {
                        closeSnackbar(loadingID);
                        return response;
                    })
                    .then((response) => {
                        if (response.data.success) {
                            document.title = getPossessive(response.data.data.name) + " Achievements | Notifly.zone";
                            enqueueSnackbar("Success", { variant: "success" });
                            setAchievementData(response.data.data);
                            history.push("/achievements/"+response.data.data.uuid)
                        } else {
                            document.title = "Notifly.zone";
                            enqueueSnackbar(response.data.cause, { variant: "error" });
                        }
                    })
                    .catch((error) => {
                        document.title = "Notifly.zone";
                        enqueueSnackbar(error.cause, { variant: "error" });
                    })
                    .finally(() => {
                        setLoading(false);
                        props.handleLoading !== undefined && props.handleLoading(false);
                    });
                break;
        }

    }

    /**
     * Handles when the user submits the form (presses enter)
     * @param event enter click
     */
    function handleSubmit(event) {
        event.preventDefault();
        if (!isLoading) handleButtonClick()
    }

    return (
        <Paper style={{maxWidth: props.maxWidth}} component="form" className={classes.search} onSubmit={handleSubmit}>
            {genericData.names
                ?   <Tooltip
                        arrow
                        title={
                            <React.Fragment>
                                {genericData.names.join(", ")}
                            </React.Fragment>
                        }
                    >
                        <PeopleAlt className={classes.accountIcon}/>
                    </Tooltip>
                :   <AccountCircle className={classes.accountIcon}/>
            }
            <InputBase
                onChange={(e) => setIgn(e.target.value)}
                disabled={isLoading}
                placeholder={props.maxWidth && genericData.name ? genericData.name : "Username"}
                className={classes.input}
            />
            <IconButton
                type="submit"
                className={classes.iconButton}
                disabled={isLoading}
                onClick={!isLoading ? handleButtonClick : null}
                aria-label="search"
            >
                <SearchIcon />
            </IconButton>
        </Paper>
    )
}